import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Header from './header';
// ----------------------------------------------------------------------

export default function PlaygroundLayout({ children }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

PlaygroundLayout.propTypes = {
  children: PropTypes.node,
};
