import { AddTwoTone, Remove } from '@mui/icons-material';
import {
  Button,
  capitalize,
  Card,
  Divider,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Confirm } from 'notiflix';
import { enqueueSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomCardHeader from 'src/components/custom-card-header';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import axiosInstance from 'src/utils/axios';
import { getErrorMessage } from 'src/utils/helpers';

export default function CodingBlockEditor({ selectedContent, setSelectedContent }) {
  const [tableData, setTableData] = useState({});
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedContentIds, setSelectedContentIds] = useState([]);

  useEffect(() => {
    if (selectedContent?.contents && selectedContent?.contents.length > 0) {
      setSelectedContentIds(selectedContent?.contents.map((content) => content._id));
    }
  }, [selectedContent]);

  useEffect(() => {
    if (!filters?.search || filters?.search?.length < 1) {
      getExamples({
        ids: selectedContent?.contents?.map((content) => content?._id),
      });
    } else {
      getExamples(filters);
    }
  }, [filters]);

  const getExamples = async ({ ..._filters }) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get('/contents', {
        params: {
          ..._filters,
          type: 'coding',
        },
      });
      if (resp?.status === 200) {
        setTableData(resp?.data || {});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  };

  const handleSelectRemove = (row, action) => {
    if (action === 'add') {
      const _contents = selectedContent?.contents || [];
      // check if code with selected language already exists
      if (_contents.find((content) => content?.language === row?.language)) {
        enqueueSnackbar(`Code with language "${row?.language}" already exists`, {
          variant: 'error',
        });
        return;
      }

      setSelectedContent({
        ...selectedContent,
        contents: [..._contents, row],
      });
    } else {
      // remove
      setSelectedContent({
        ...selectedContent,
        contents: selectedContent?.contents.filter((content) => content?._id !== row?._id),
      });
    }
  };

  return (
    <div>
      <Divider sx={{ my: 2 }} />
      <CustomCardHeader
        title={<Typography variant="h6">Search code blocks</Typography>}
        action={
          <Button
            component={Link}
            to={`${paths.dashboard.examples.new}?type=coding`}
            size="small"
            color="warning"
            startIcon={<Iconify icon="eva:plus-fill" />}
            target="_blank"
          >
            Create New
          </Button>
        }
      />
      <Stack spacing={2} my={2}>
        <TextField
          fullWidth
          value={filters?.search || ''}
          onChange={(e) => {
            setFilters({
              ...filters,
              search: e.target.value,
            });
          }}
          placeholder="Search by title or tags"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
          size="small"
        />
        {loading && <LinearProgress />}
        <Card sx={{ p: 1 }} variant="outlined">
          <List sx={{ width: '100%' }}>
            {tableData?.data?.map((row, idx) => (
              <Fragment key={idx}>
                <ListItem>
                  <ListItemText primary={row?.title} secondary={capitalize(row?.language || '')} />
                  {selectedContentIds.includes(row?._id) ? (
                    <Button
                      color="error"
                      startIcon={<Remove />}
                      size="small"
                      onClick={() => {
                        Confirm.show(
                          'Confirmation',
                          'The content will be removed upon proceeding, Are you sure to proceed?',
                          'Confirm',
                          'Cancel',
                          () => handleSelectRemove(row, 'remove'),
                          () => {},
                          {
                            width: '500px',
                            messageMaxLength: 1000,
                          }
                        );
                      }}
                    >
                      Remove
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<AddTwoTone />}
                      onClick={() => handleSelectRemove(row, 'add')}
                    >
                      Select
                    </Button>
                  )}
                </ListItem>
                {idx !== tableData?.data?.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        </Card>
      </Stack>
    </div>
  );
}
