import { useSnackbar } from 'notistack';
import { useState, createContext, useContext, useEffect } from 'react';
import axiosInstance, { handleLogout } from 'src/utils/axios';
import { getErrorMessage } from 'src/utils/helpers';

export const LearningHubContext = createContext({});

export const useLearningHubContext = () => {
  return useContext(LearningHubContext);
};

export const LearningHubProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [enrollment, setEnrollment] = useState(null);

  // logout if not loggedin
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      handleLogout();
    }
  }, []);

  const handleGetEnrollment = async (enrollmentId) => {
    try {
      const resp = await axiosInstance.get(`/enrollments/${enrollmentId}`);
      if (resp.status === 200) {
        setEnrollment(resp.data?.data || null);
        return resp?.data?.data || null;
      }
      return null;
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return null;
    }
  };

  return (
    <LearningHubContext.Provider
      value={{
        // state
        enrollment,
        // methods
        // functions
        handleGetEnrollment,
      }}
    >
      {children}
    </LearningHubContext.Provider>
  );
};
