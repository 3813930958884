import { AddTwoTone, Done } from '@mui/icons-material';
import {
  Button,
  capitalize,
  Card,
  Divider,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomCardHeader from 'src/components/custom-card-header';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import axiosInstance from 'src/utils/axios';
import { getErrorMessage } from 'src/utils/helpers';

export default function VideoBlockEditor({ selectedContent, setSelectedContent }) {
  const [tableData, setTableData] = useState({});
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getExamples(filters);
  }, [filters]);

  const getExamples = async ({ ..._filters }) => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get('/contents', {
        params: {
          ..._filters,
          type: 'video',
        },
      });
      if (resp?.status === 200) {
        setTableData(resp?.data || {});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  };

  const handleSelect = (row) => {
    setSelectedContent({
      ...selectedContent,
      contents: [row],
    });
  };

  return (
    <div>
      <Divider sx={{ my: 2 }} />
      <CustomCardHeader
        title={<Typography variant="h6">Search code blocks</Typography>}
        action={
          <Button
            component={Link}
            to={`${paths.dashboard.examples.new}?type=video`}
            size="small"
            color="warning"
            startIcon={<Iconify icon="eva:plus-fill" />}
            target="_blank"
          >
            Create New
          </Button>
        }
      />
      <Stack spacing={2} my={2}>
        <TextField
          fullWidth
          value={filters?.search || ''}
          onChange={(e) => {
            setFilters({
              ...filters,
              search: e.target.value,
            });
          }}
          placeholder="Search by title or tags"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
          size="small"
        />
        {loading && <LinearProgress />}
        <Card sx={{ p: 1 }} variant="outlined">
          <List sx={{ width: '100%' }}>
            {tableData?.data?.map((row, idx) => (
              <Fragment key={idx}>
                <ListItem>
                  <ListItemText primary={row?.title} secondary={capitalize(row?.language || '')} />
                  {row?._id === selectedContent?.contents?.[0]?._id ? (
                    <Button
                      color="success"
                      variant="contained"
                      startIcon={<Done />}
                      size="small"
                      onClick={() => handleSelect(row)}
                    >
                      Selected
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<AddTwoTone />}
                      onClick={() => handleSelect(row)}
                    >
                      Select
                    </Button>
                  )}
                </ListItem>
                {idx !== tableData?.data?.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        </Card>
      </Stack>
    </div>
  );
}
