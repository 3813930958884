import { Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';

export default function UploadFile({ name, formInput, setFormInput }) {
  const inputFile = useRef(null);
  const dropArea = useRef(null);

  const processFiles = (e, files) => {
    e.preventDefault();
    dropArea.current.style.backgroundColor = '#fff';

    if (files.length) {
      setFormInput({
        ...formInput,
        [name]: files[0], // TODO: Add support for multiple files
      });
    }
  };

  return (
    <div>
      <Stack
        sx={{
          borderWidth: '1px',
          borderStyle: 'dashed',
          borderColor: 'divider',
          borderRadius: 2,
          cursor: 'pointer',
          padding: '30px',
        }}
        onClick={() => inputFile.current.click()}
        onDrop={(e) => processFiles(e, e.dataTransfer.files)}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={(e) => e.preventDefault()}
        ref={dropArea}
      >
        <Typography variant="body1" textAlign="center">
          Drag & drop files or click to browse. (max size: 10MB)
        </Typography>
        <input
          type="file"
          accept="*"
          multiple={false}
          ref={inputFile}
          onChange={(e) => processFiles(e, inputFile.current.files)}
          style={{ display: 'none' }}
        />
      </Stack>
    </div>
  );
}
