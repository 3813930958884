import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import Image from '../image';

// ----------------------------------------------------------------------

function Logo({ sx }) {
  return (
    <Link
      component={RouterLink}
      to="/"
      color="inherit"
      aria-label="go to homepage"
      sx={{ lineHeight: 0 }}
    >
      <Box
        sx={{
          width: 50,
          lineHeight: 0,
          cursor: 'pointer',
          display: 'inline-flex',
          ...sx,
        }}
      >
        <Image src="/logo/logo_single.png" alt="logo" />
      </Box>
    </Link>
  );
}

export default memo(Logo);
