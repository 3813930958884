import React from 'react';
import { Button, Divider, IconButton, Popover, Stack, capitalize } from '@mui/material';
import {
  Brightness4Outlined,
  CloseFullscreenOutlined,
  ContentCopyOutlined,
  // FormatAlignLeftOutlined,
  KeyboardArrowDown,
  LightModeOutlined,
  OpenInFullOutlined,
  RestartAltOutlined,
} from '@mui/icons-material';

// import prettier from 'prettier';
// import parserBabel from 'prettier/parser-babel';
// import parserHtml from 'prettier/parser-html';
// import parserTypescript from 'prettier/parser-typescript';
// import parserPostcss from 'prettier/parser-postcss';
// import parserYaml from 'prettier/parser-yaml';
import { splitArray } from 'src/utils/helpers';
import { usePlaygroundContext } from 'src/context/PlaygroundContext';
import { enqueueSnackbar } from 'notistack';
import { SUPPORTED_LANGUAGES } from 'src/utils/constants/coding-editor-constants';

export default function EditorActions() {
  const {
    code,
    selectedLanguage,
    monacoTheme,
    setMonacoTheme,
    setSelectedLanguage,
    handleResetCode,
  } = usePlaygroundContext();
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(code);
    enqueueSnackbar('Code copied to clipboard');
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  // Prettier options
  // const handleFormatCode = async () => {
  //   if (!code) return;

  //   try {
  //     const formattedCode = await prettier.format(code, {
  //       parser: selectedLanguage === 'javascript' ? 'babel' : selectedLanguage,
  //       plugins: [parserBabel, parserHtml, parserTypescript, parserPostcss, parserYaml],
  //     });
  //     console.log('formattedCode', formattedCode);
  //     handleChangeCode(formattedCode);
  //   } catch (error) {
  //     console.error('Error formatting code:', error);
  //     return;
  //   }
  // };

  // Actions
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Stack
      direction="row"
      sx={{
        // bgcolor: 'background.default',
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
      }}
      spacing={1}
      p={0.4}
      px={1}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {/* <Stack direction="row" spacing={1}>
        <Button
          aria-describedby={id}
          onClick={handleClick}
          endIcon={<KeyboardArrowDown />}
          size={'small'}
        >
          {capitalize(
            (selectedLanguage
              ? SUPPORTED_LANGUAGES?.find((lang) => lang.value === selectedLanguage)?.label
              : '') || ''
          )}
        </Button>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Stack
            sx={{
              width: 600,
            }}
          >
            {splitArray(SUPPORTED_LANGUAGES, 2).map((langs, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                divider={<Divider orientation="vertical" flexItem />}
              >
                {langs?.map((lang, idx) => (
                  <Button
                    key={idx}
                    fullWidth
                    onClick={() => {
                      setSelectedLanguage(lang?.value);
                      localStorage.setItem('monaco-language', lang?.value);
                      handleClose();
                    }}
                    sx={{
                      justifyContent: 'flex-start',
                      textTransform: 'none',
                    }}
                    size="large"
                  >
                    {capitalize(lang?.label || '')}
                  </Button>
                ))}
              </Stack>
            ))}
          </Stack>
        </Popover>
      </Stack> */}
      <Stack direction="row" spacing={1}>
        {/* <IconButton size={'small'} onClick={handleFormatCode} title="Format Code">
          <FormatAlignLeftOutlined fontSize="1rem" />
        </IconButton> */}
        <IconButton size={'small'} onClick={handleCopyCode} title="Copy Code">
          <ContentCopyOutlined fontSize="1rem" />
        </IconButton>
        <IconButton size={'small'} onClick={handleResetCode} title="Reset Code">
          <RestartAltOutlined fontSize="1rem" />
        </IconButton>
        <IconButton
          size={'small'}
          onClick={() => {
            const theme = monacoTheme === 'vs-dark' ? 'light' : 'vs-dark';
            setMonacoTheme(theme);
            localStorage.setItem('monaco-theme', theme);
          }}
          title={`Click for ${monacoTheme === 'vs-dark' ? 'Light' : 'Dark'} Mode`}
        >
          {monacoTheme === 'vs-dark' ? (
            <LightModeOutlined fontSize="1rem" />
          ) : (
            <Brightness4Outlined fontSize="1rem" />
          )}
        </IconButton>
        <IconButton
          size={'small'}
          onClick={toggleFullScreen}
          title={isFullScreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
        >
          {isFullScreen ? (
            <CloseFullscreenOutlined fontSize="1rem" />
          ) : (
            <OpenInFullOutlined fontSize="1rem" />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
}
