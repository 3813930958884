import React, { useState } from 'react';
import SingleColumn from './SingleColumn';
import DoubleColumns from './DoubleColumns';
import ThreeColumns from './ThreeColumns';
import OneByTwoColumns from './OneByTwoColumns';
import TwoByOneColumns from './TwoByOneColumns';
import SectionContent from '../sections';
import { useResourceHubContext } from 'src/context/ResourceHubContext';
import SectionContentEditor from '../sections/ContentEditor';
import ContentWrapper from './ContentWrapper';

// props > check sections schema in backend
export default function ModuleLayouts({ _id, hideActions, layoutType, contents, ...rest }) {
  const { mode, handleEditModuleSection, handleDeleteSection } = useResourceHubContext();
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedContentIndex, setSelectedContentIndex] = useState(null);
  const [openContentEditor, setOpenContentEditor] = useState(false);
  const [isWait, setIsWait] = useState(false);

  const handleSaveContent = async (_action) => {
    setIsWait(true);

    let action = null;
    if (_action) {
      action = _action; // remove, delete-empty-content
    } else if (selectedContent?._id) {
      action = null;
    } else {
      action = 'add';
    }

    await handleEditModuleSection({
      action,
      sectionId: _id,
      contentId: selectedContent?._id,
      position: selectedContentIndex,
      ...(selectedContent || {}),
    });

    setIsWait(false);
    if (action === 'remove') {
      setSelectedContent(null);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setOpenContentEditor(false);
    setSelectedContent(null);
    setSelectedContentIndex(null);
  };

  const getContent = (_contentPosition) => {
    const content = contents?.find((c) => c?.position === _contentPosition);
    return (
      <>
        <ContentWrapper
          editContent={() => {
            setSelectedContent(content);
            setSelectedContentIndex(_contentPosition);
            setOpenContentEditor(true);
          }}
          handleDelete={async () => {
            setIsWait(true);

            await handleDeleteSection(
              _id, // sectionId
              content?._id
            );
            setIsWait(false);
          }}
          isWait={isWait}
          hideActions={hideActions}
        >
          <SectionContent {...content} />
        </ContentWrapper>
      </>
    );
  };

  const layoutComponents = {
    single: <SingleColumn content1={getContent(0)} {...rest} />,
    double: <DoubleColumns content1={getContent(0)} content2={getContent(1)} {...rest} />,
    triple: (
      <ThreeColumns
        content1={getContent(0)}
        content2={getContent(1)}
        content3={getContent(2)}
        {...rest}
      />
    ),
    oneByTwo: (
      <OneByTwoColumns
        content1={getContent(0)}
        content2={getContent(1)}
        content3={getContent(2)}
        {...rest}
      />
    ),
    twoByOne: (
      <TwoByOneColumns
        content1={getContent(0)}
        content2={getContent(1)}
        content3={getContent(2)}
        {...rest}
      />
    ),
  };

  return (
    <>
      {layoutComponents[layoutType] || null}
      {mode === 'edit' && !!openContentEditor && (
        <SectionContentEditor
          isWait={isWait}
          selectedContent={selectedContent}
          setSelectedContent={setSelectedContent}
          handleSaveContent={handleSaveContent}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
