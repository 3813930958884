import { Grid } from '@mui/material';
import React from 'react';

export default function DoubleColumns({ content1, content2 }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        {content1}
      </Grid>
      <Grid item xs={6}>
        {content2}
      </Grid>
    </Grid>
  );
}
