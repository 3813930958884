// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// _mock
import { _socials } from 'src/_mock';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const LINKS = [
  { name: 'About us', href: paths.about },
  { name: 'Contact us', href: paths.contact },
  { name: 'FAQs', href: paths.faqs },
  { name: 'Terms and Condition', href: '#' },
  { name: 'Privacy Policy', href: '#' },
  { name: 'support@minimals.cc', href: '#' },
];

// ----------------------------------------------------------------------

export default function MinimalFooter() {
  return (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider sx={{ my: 1 }} />

      <Container>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', md: 'center' }}
          spacing={5}
          mb={1}
        >
          <Logo />
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'center'}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {LINKS.map((link) => (
              <Typography
                key={link.name}
                spacing={2}
                sx={{ width: 1, minWidth: 'max-content' }}
                component={RouterLink}
                href={link.href}
                color="text.secondary"
                variant="body2"
              >
                {link.name}
              </Typography>
            ))}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
