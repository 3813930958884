import { format, getTime, differenceInMinutes, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

// TODO: remove above code and replace with below code

export const getDateDifference = (date) => {
  let diff = differenceInMinutes(new Date(), new Date(date));
  if (diff < 60) return diff + ' minutes ago';
  diff = Math.ceil(diff / 60);
  if (diff < 24) return `${diff} hour${diff === 0 ? '' : 's'} ago`;
  diff = Math.ceil(diff / 24);
  if (diff < 30) return `${diff} day${diff === 0 ? '' : 's'} ago`;
  diff = Math.ceil(diff / 30);
  if (diff < 12) return `${diff} month${diff === 0 ? '' : 's'} ago`;
  diff = diff / 12;
  return `${diff.toFixed(1)} year${Math.ceil(diff) === 0 ? '' : 's'} ago`;
};

// ----------------------------------------------------------------------

export const fDate = (date) => {
  if (!date) return '';
  if (!dayjs(date)?.isValid()) return 'Invalid Date';
  return format(new Date(date), 'dd MMMM yyyy');
};

export const fTime = (date) => {
  if (!date) return '';
  return format(new Date(date), 'hh:mm a');
};

export const fDateTime = (date) => {
  if (!date) return '';
  return format(new Date(date), 'dd MMM, yyyy HH:mm');
};

export const fDateTimeSuffix = (date) => {
  if (!date) return '';
  return format(new Date(date), 'dd MMM yyyy p');
};

export const fDateTimeFull = (date) => {
  if (!date) return '';
  // eg. 11 Aug 2024 10:23:20
  return format(new Date(date), 'dd MMM yyyy HH:mm:ss');
};

export const fToNow = (date) => {
  if (!date) return '-';
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
};

export const getLocaleDate = (date) => {
  return date ? new Date(date)?.toString() : '-';
};

export const fCustomDate = (date, formatStr) => {
  if (!date) return '';
  return format(new Date(date), formatStr);
};
