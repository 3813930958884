import React from 'react';
import { Box } from '@mui/material';
import EmptyContent from 'src/components/empty-content';

export default function VideoPreview({ sx, item, type }) {
  if (!item) return <EmptyContent title={'No video to preview'} />;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        minHeight: '300px',
        ...sx,
      }}
    >
      {type === 'embed' ? (
        <div
          dangerouslySetInnerHTML={{
            __html: item,
          }}
        />
      ) : [
          'https://youtu.be',
          'https://www.youtube.com',
          'https://vimeo.com',
          'https://player.vimeo.com',
        ].some((url) => item?.startsWith(url)) ? (
        <iframe
          src={item}
          title="Video preview"
          style={{ width: '100%', height: '300px', borderRadius: 10 }}
          allowFullScreen
        />
      ) : (
        <video controls style={{ width: '100%', height: '300px', borderRadius: 10 }}>
          <source src={item} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </Box>
  );
}
