import { Stack, useTheme } from '@mui/material';
import React from 'react';
import QuestionView from '../questions/views/QuestionView';

export default function QuizQuestion({ question }) {
  const theme = useTheme();

  return (
    <Stack>
      <QuestionView question={question} />
    </Stack>
  );
}
