import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import AuthModernLayout from 'src/layouts/auth/modern';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import { AuthProvider } from 'src/context/AuthContext';

// ----------------------------------------------------------------------

// MODERN
const LoginModernPage = lazy(() => import('src/pages/auth/login'));
const RegisterModernPage = lazy(() => import('src/pages/auth/register'));
const ForgotPasswordModernPage = lazy(() => import('src/pages/auth/forgot-password'));
const VerifyModernPage = lazy(() => import('src/pages/auth/verify'));
const NewPasswordModernPage = lazy(() => import('src/pages/auth/new-password'));

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <AuthProvider>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </AuthProvider>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthModernLayout>
            <LoginModernPage />
          </AuthModernLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <AuthModernLayout>
            <RegisterModernPage />
          </AuthModernLayout>
        ),
      },
      {
        element: (
          <AuthModernCompactLayout>
            <Outlet />
          </AuthModernCompactLayout>
        ),
        children: [
          { path: 'forgot-password', element: <ForgotPasswordModernPage /> },
          { path: 'new-password', element: <NewPasswordModernPage /> },
          { path: 'verify', element: <VerifyModernPage /> },
        ],
      },
    ],
  },
];
