import { Box, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

export default function CustomTextField({
  value,
  onChange,
  title,
  action,
  placeholder,
  helperText,
  ...other
}) {
  return (
    <Stack>
      <Stack direction={'row'} justifyContent={'space-between'}>
        {title && <Typography my={1}>{title}</Typography>}
        {action && <Box>{action}</Box>}
      </Stack>
      <TextField value={value} onChange={onChange} placeholder={placeholder || ''} {...other} />
      <FormHelperText>{helperText}</FormHelperText>
    </Stack>
  );
}
