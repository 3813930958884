import { capitalize, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import CircularChart from 'src/components/chart/charts/CircularChart';
import { useLearningHubContext } from 'src/context/LearningHubContext';

export default function LearningProgressCard() {
  const { enrollment } = useLearningHubContext();
  const theme = useTheme();

  return (
    <div>
      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'center'}
        width={1}
        sx={{
          background: theme.palette.background.paper,
          borderRadius: 2,
        }}
        pr={2}
      >
        <CircularChart
          title={`Resource Hub learning progress ${enrollment?.totalModules > 0 ? `(${enrollment?.completedModules}/${enrollment?.totalModules})}` : ''}`}
          total={1}
          color="info"
          chart={{
            series:
              enrollment?.totalModules > 0
                ? Math.ceil((enrollment?.completedModules / enrollment?.totalModules) * 100)
                : 0,
          }}
          width={50}
          height={50}
          fontSize="0.75rem"
        />
        <Stack>
          <Typography variant="subtitle1">
            {enrollment?.completedModules || 0} /{enrollment?.totalModules} modules completed
          </Typography>
          <Typography variant="subtitle2" color={'text.secondary'}>
            {capitalize(enrollment?.category || 'Learning Progress')}
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
}
