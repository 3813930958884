import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { createContext, useContext, useEffect, useState } from 'react';
import { codeSample } from 'src/_mock/_examples';
import axiosInstance, { handleLogout } from 'src/utils/axios';
import { getErrorMessage, isJsonString } from 'src/utils/helpers';

export const PlaygroundContext = createContext({});

export const usePlaygroundContext = () => {
  return useContext(PlaygroundContext);
};

export const PlaygroundProvider = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [code, setCode] = useState('');
  const [output, setOutput] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('c++');
  const [monacoTheme, setMonacoTheme] = useState('vs-dark');

  // logout if not loggedin
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      handleLogout();
    }
  }, []);

  useEffect(() => {
    const theme = localStorage.getItem('monaco-theme');
    if (theme) {
      setMonacoTheme(theme);
    }

    const _language = localStorage.getItem('monaco-language');
    if (_language) {
      setSelectedLanguage(_language);
    }
  }, []);

  const closeFile = (fileId) => {
    const currentFileIndex = selectedFiles.findIndex((file) => file.id === fileId);
    if (currentFileIndex > -1) {
      const filteredFiles = selectedFiles.filter((file) => file.id !== fileId);
      if (filteredFiles.length === 0) {
        setCurrentFile(null);
      } else if (filteredFiles.length === 1) {
        setCurrentFile(filteredFiles[0].id);
      } else {
        if (currentFileIndex === selectedFiles.length - 1) {
          setCurrentFile(filteredFiles[currentFileIndex - 1].id);
        } else {
          setCurrentFile(filteredFiles[currentFileIndex + 1].id);
        }
      }
      setSelectedFiles(filteredFiles);
    }
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode);
  };

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  const handleThemeChange = (newTheme) => {
    setMonacoTheme(newTheme);
  };

  const handleResetCode = () => {
    setCode(codeSample);
  };

  const handleGetOutput = async (data) => {
    try {
      const resp = await axios.get(data?.data);
      if (resp.status === 202) {
        const _data = isJsonString(resp?.data?.data)
          ? JSON.parse(resp?.data?.data)
          : resp?.data?.data;
        if (['Queued', 'Processing'].includes(_data?.status)) {
          // send request again after 2 seconds
          setTimeout(() => {
            handleGetOutput(data);
          }, 2000);
        } else {
          setOutput(resp?.data);
        }
      } else {
        // failed
        setOutput({
          message: 'Unknown error',
          data: null,
          err: null,
          success: false,
        });
      }
    } catch (error) {
      setOutput({
        message: error.response?.data?.message,
        data: null,
        err: error.response?.data?.err,
        success: false,
      });
    }
  };

  const handleRunCode = async () => {
    try {
      // const resp = await axios.post(process.env.REACT_APP_CODE_URL + '/submit', {
      const resp = await axios.post('http://localhost:8080/api/code/run', {
        src: code,
        stdin: '',
        lang: selectedLanguage,
      });

      if (resp.status === 202) {
        // get result
        await handleGetOutput(resp.data);
      } else {
        // failed
        setOutput({
          message: 'Unknown error',
          data: null,
          err: null,
          success: false,
        });
      }
    } catch (error) {
      setOutput({
        message: error.response?.data?.message,
        data: null,
        err: error.response?.data?.err,
        success: false,
      });
    }
  };

  const handleUploadCode = async () => {
    try {
      const resp = await axiosInstance.post(process.env.REACT_APP_API_URL + '/code/upload', {
        code: code,
        lang: selectedLanguage,
        options: {
          deviceId: '1',
          sub_command: 'upload',
        },
      });
      if (resp.status === 200) {
        // enqueueSnackbar('Uploading...', { variant: 'info' });
      }
      return true;
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return true;
    }
  };

  return (
    <PlaygroundContext.Provider
      value={{
        // state
        code,
        selectedLanguage,
        monacoTheme,
        output,
        selectedFiles,
        currentFile,

        // methods
        setSelectedLanguage,
        setMonacoTheme,
        setCurrentFile,
        setSelectedFiles,

        // actions
        handleCodeChange,
        handleLanguageChange,
        handleThemeChange,
        handleResetCode,
        handleRunCode,
        closeFile,
        handleUploadCode,
      }}
    >
      {children}
    </PlaygroundContext.Provider>
  );
};
