import { useMemo } from 'react';
import { useLocales } from 'src/locales';
import { useGlobalContext } from 'src/context/GlobalContext';
import { navItemsByAccountType } from './nav-items';

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { currentUser } = useGlobalContext();

  const data = useMemo(
    () => navItemsByAccountType[currentUser?.accountType || 'user'],
    [t, currentUser?.accountType]
  );

  return data;
}
