import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import ResourceHubLayout from 'src/layouts/resourcehub';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { ResourceHubProvider } from 'src/context/ResourceHubContext';

// ----------------------------------------------------------------------

// OVERVIEW
const ResourceHub = lazy(() => import('src/pages/resource-hub'));
// ----------------------------------------------------------------------

export const resourceHubRoutes = [
  {
    path: 'resource-hub',
    element: (
      <ResourceHubProvider>
        <ResourceHubLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ResourceHubLayout>
      </ResourceHubProvider>
    ),
    children: [
      {
        path: ':resourceHubId',
        element: <ResourceHub />,
      },
      {
        path: ':resourceHubId/:moduleSlug',
        element: <ResourceHub />,
      },
    ],
  },
];
