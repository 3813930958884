import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { useResponsive } from 'src/hooks/use-responsive';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { useSearchParams } from 'react-router-dom';
import SidebarItems from './SidebarItems';
import { RESOURCES_NAV } from 'src/layouts/config-layout';
import { useResourceHubContext } from 'src/context/ResourceHubContext';

export default function ResourceHubSidebar({ openNav, onCloseNav }) {
  const [searchParams] = useSearchParams();
  const isCompact = searchParams.has('compact');

  const { resourceHubSidebar } = useResourceHubContext();
  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <SidebarItems
        data={resourceHubSidebar || []}
        hideSubHeader={isCompact}
        config={{
          isCompact,
        }}
        sx={{
          my: 2,
        }}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return resourceHubSidebar?.length > 0 ? (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: isCompact ? RESOURCES_NAV.W_MINI : RESOURCES_NAV.W_VERTICAL },
      }}
    >
      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: isCompact ? RESOURCES_NAV.W_MINI : RESOURCES_NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: isCompact ? RESOURCES_NAV.W_MINI : RESOURCES_NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  ) : null;
}
