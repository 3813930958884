import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useGlobalContext } from 'src/context/GlobalContext';
import AccountPopover from './account-popover';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function LoginButton({ sx }) {
  const { currentUser } = useGlobalContext();

  return currentUser && currentUser?.user ? (
    <AccountPopover />
  ) : (
    <Button component={RouterLink} href={paths.auth.login} variant="outlined" sx={{ mr: 1, ...sx }}>
      Login
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
};
