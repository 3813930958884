import { Button, Card, Stack, styled, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CodeBlock from '../CodeBlock';
import MiniTabs from 'src/components/custom-tabs/MiniTabs';
import { Link } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';
import { useLearningHubContext } from 'src/context/LearningHubContext';

export default function CodeBlocks({ codes, ...props }) {
  const [selectedCode, setSelectedCode] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs(
      languages.map((language) => ({
        label: language,
        value: language,
      }))
    );
  }, [languages]);

  useEffect(() => {
    if (codes?.length) {
      setSelectedCode(codes[0]);
      setLanguages(codes.map((code) => code.language));
    }
  }, [codes]);

  const pathname = usePathname();
  const [whichPage, setWhichPage] = useState('');
  const { enrollment } = useLearningHubContext();

  useEffect(() => {
    const pathSplit = pathname.split('/');
    setWhichPage(pathSplit[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const codeActions = (
    <Stack mx={1}>
      <Button
        color="primary"
        variant="contained"
        size="small"
        component={Link}
        to={paths.playground.coding}
      >
        Try it
      </Button>
    </Stack>
  );

  return codes?.length === 0 ? (
    <Stack height={1} justifyContent={'center'} alignItems={'center'} direction={'column'}>
      No code provided
    </Stack>
  ) : (
    <Card sx={{ position: 'relative' }} variant={'outlined'}>
      <Stack>
        <MiniTabs
          value={selectedCode?.language}
          onChange={(value) => {
            setSelectedCode(codes.find((code) => code.language === value));
          }}
          tabs={tabs}
        >
          {whichPage === 'learning' && enrollment && codeActions}
        </MiniTabs>
      </Stack>
      <Stack>
        <CodeBlock
          code={selectedCode?.content || ''}
          language={selectedCode?.language || 'Python'}
          sx={{ height: 400 }}
        />
      </Stack>
    </Card>
  );
}
