import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Rating,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FilePreview from 'src/components/fileupload/FilePreview';
import UploadFile from 'src/components/fileupload/UploadFile';

export default function QuestionInputField({
  question,
  handleFormInput,
  formInput,
  setFormInput,
  disabled,
}) {
  const [hValue, setHValue] = useState(-1);

  const handleCheckboxChange = (e, optionLabel) => {
    const updatedValues = e.target.checked
      ? [...(formInput[question?.name] || []), optionLabel]
      : formInput[question?.name]?.filter((item) => item !== optionLabel);

    setFormInput({
      ...formInput,
      [question?.name]: updatedValues,
    });
  };

  switch (question?.type) {
    case 'text':
    case 'textarea':
      return (
        <TextField
          fullWidth
          disabled={disabled}
          variant="outlined"
          placeholder={question?.placeholder || ''}
          name={question?.name}
          required={question?.required}
          onChange={handleFormInput}
          value={formInput[question?.name] || question?.answer || ''}
          multiline={question?.type === 'textarea'}
          rows={question?.type === 'textarea' ? 4 : 1}
        />
      );
    case 'checkbox':
      return (
        <FormGroup>
          {question?.options?.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  disabled={disabled}
                  name={question?.name}
                  checked={
                    question?.answer?.includes(option?.label) ||
                    formInput[question?.name]?.includes(option?.label)
                  }
                  onChange={(e) => handleCheckboxChange(e, option?.label)}
                />
              }
              label={option?.label || ''}
            />
          ))}
        </FormGroup>
      );
    case 'radio':
      return (
        <FormControl disabled={disabled}>
          <RadioGroup
            name={question?.name}
            value={formInput[question?.name] || question?.answer || ''}
            onChange={handleFormInput}
          >
            {question?.options?.map((option, index) => (
              <FormControlLabel
                key={index}
                control={<Radio value={option?.label || ''} />}
                label={option?.label || ''}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    case 'select':
      return (
        <FormControl fullWidth>
          <InputLabel>{question?.placeholder || ''}</InputLabel>
          <Select
            disabled={disabled}
            label={question?.placeholder || ''}
            name={question?.name}
            value={formInput[question?.name] || question?.answer || ''}
            onChange={handleFormInput}
          >
            {question?.options?.map((option) => (
              <MenuItem key={option._id} value={option?.label || ''}>
                {option?.label || ''}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case 'rating':
      return (
        <Tooltip title={hValue !== -1 ? question?.options[hValue - 1]?.label || '' : ''}>
          <Rating
            disabled={disabled}
            name={question?.name}
            value={formInput[question?.name] || question?.answer || ''}
            max={question?.options?.length || 5}
            onChange={(e, value) => setFormInput({ ...formInput, [question?.name]: value })}
            onChangeActive={(e, hoverValue) => setHValue(hoverValue)}
          />
        </Tooltip>
      );
    case 'file':
      return (
        <>
          <UploadFile name={question?.name} formInput={formInput} setFormInput={setFormInput} />
          <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
            {question?.files?.map((file, index) => (
              <FilePreview key={index} file={file} />
            ))}
          </Stack>
        </>
      );
    case 'date':
      const answer = formInput[question?.name] || question?.answer || null;
      const _correctAnswer = answer && dayjs(answer)?.isValid() ? dayjs(answer) : null;
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            name={question?.name}
            label={question?.placeholder || ''}
            disabled={disabled}
            value={_correctAnswer}
            onChange={(newValue) => setFormInput({ ...formInput, [question?.name]: newValue })}
          />
        </LocalizationProvider>
      );
    default:
      return null;
  }
}
