import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import ModuleLayouts from 'src/sections/Resourcehub/layouts';
import { getErrorMessage } from 'src/utils/helpers';
import { Stack } from '@mui/material';
import { useResourceHubContext } from 'src/context/ResourceHubContext';
import { useLearningHubContext } from 'src/context/LearningHubContext';

export default function LearningHub() {
  const { enrollmentId } = useParams();
  const { moduleSections, handleGetResourceHub, handleEmptyResourceHub } = useResourceHubContext();
  const { handleGetEnrollment } = useLearningHubContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!enrollmentId) {
      setLoading(false);
      enqueueSnackbar('ID is missing', { variant: 'error' });
      return;
    }

    handlGetData();

    return () => {
      handleEmptyResourceHub();
    };
  }, [enrollmentId]);

  const handlGetData = async () => {
    try {
      setLoading(true);
      const enrollment = await handleGetEnrollment(enrollmentId);
      if (enrollment && enrollment?.resourceHub) {
        await handleGetResourceHub(enrollment?.resourceHub, {
          category: enrollment?.category,
        });
      } else {
        enqueueSnackbar('Invalid Enrollment ID', { variant: 'error' });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  };

  return loading ? (
    <LoadingScreen />
  ) : (
    <Stack spacing={1} pb={10}>
      <Stack spacing={6}>
        {moduleSections.map((moduleSection, index) => (
          <ModuleLayouts key={index} {...moduleSection} />
        ))}
      </Stack>
    </Stack>
  );
}
