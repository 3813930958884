// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useGlobalContext } from 'src/context/GlobalContext';
import { getImageUrl } from 'src/utils/helpers';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { currentUser } = useGlobalContext();

  return (
    <Stack
      sx={{
        p: 2,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar
            src={getImageUrl(currentUser?.profileImage)}
            alt={currentUser?.firstName + ' ' + (currentUser?.lastName || '')}
            sx={{ width: 48, height: 48 }}
          />
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {currentUser?.firstName + ' ' + (currentUser?.lastName || '')}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {currentUser?.email}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
