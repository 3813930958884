import React from 'react';
import { InputLabel, Stack, styled } from '@mui/material';

const CustomInputWrapper = styled(Stack)(
  ({ theme }) => `
      position: relative;
      border-width: 0.5px;
      border-style: solid;
      border-radius: ${theme.spacing(1)};
      border-color: ${theme.palette.secondary.main};
    `
);

const CustomInputLabel = styled(InputLabel)(
  ({ theme }) => `
      position: absolute;
      top: 1px;
      left: 12px;
      padding-left: 12px;
      padding-right: 12px;
      font-weight: ${theme.typography.fontWeightBold};
      transform: translateY(-50%) scale(0.85);
      background: ${theme.palette.background.default};
      border-radius: 4px;
    `
);

export default function CustomCardWithLabel({ label, children, sx, ...rest }) {
  return (
    <CustomInputWrapper sx={sx}>
      <CustomInputLabel sx={{ zIndex: 1 }}>{label}</CustomInputLabel>
      <Stack {...rest}>{children}</Stack>
    </CustomInputWrapper>
  );
}
