import { rTabs } from 'src/components/MonacoCodeEditor/options';

export const quillSample = `<p>Given an input string (<code>s</code>) and a pattern (<code>p</code>), implement wildcard pattern matching with support for <code>'?'</code> and <code>'*'</code> where:</p><ul><li><span class="hljs-string">'?'</span> <span class="hljs-title class_">Matches</span> any single character.</li><li><code>'*'</code> Matches any sequence of characters (including the empty sequence).</li></ul><p>The matching should cover the <strong>entire</strong> input string (not partial).</p><p>&nbsp;</p><p><strong>Example 1:</strong></p><blockquote><span class="hljs-title class_">Input</span>: s = <span class="hljs-string">"aa"</span>, p = <span class="hljs-string">"a"</span></blockquote><blockquote><span class="hljs-title class_">Output</span>: <span class="hljs-literal">false</span></blockquote><blockquote><span class="hljs-title class_">Explanation</span>: <span class="hljs-string">"a"</span> does not match the entire string <span class="hljs-string">"aa"</span>.</blockquote><p><strong>Example 2:</strong></p><pre class="ql-syntax" spellcheck="false"><span class="hljs-title class_">Input</span>: s = <span class="hljs-string">"aa"</span>, p = <span class="hljs-string">"*"</span>
<span class="hljs-title class_">Output</span>: <span class="hljs-literal">true</span>
<span class="hljs-title class_">Explanation</span>:&nbsp;<span class="hljs-string">'*'</span> matches any sequence.
</pre><p><strong>Example 3:</strong></p><pre class="ql-syntax" spellcheck="false">Input: s = <span class="hljs-string">"cb"</span>, p = <span class="hljs-string">"?a"</span>
Output: <span class="hljs-literal">false</span>
Explanation:&nbsp;<span class="hljs-string">'?'</span> matches <span class="hljs-string">'c'</span>, but the second letter is <span class="hljs-string">'a'</span>, <span class="hljs-built_in">which</span> does not match <span class="hljs-string">'b'</span>.
</pre><p>&nbsp;</p><p><strong>Constraints:</strong></p><ul><li><code>0 &lt;= s.length, p.length &lt;= 2000</code></li><li><code>s</code> contains only lowercase English letters.</li><li><code>p</code> contains only lowercase English letters, <code>'?'</code> or <code>'*'</code>.</li></ul><p><br></p>`;

export const codeSample = `
  // the code example from https://github.com/suren-atoyan/state-local

  import { compose, curry, isFunction } from '../utils';
  import validators from '../validators';
  
  function create(initial, handler = {}) {
    validators.initial(initial);
    validators.handler(handler);
  
    const state = { current: initial };
  
    const didUpdate = curry(didStateUpdate)(state, handler);
    const update = curry(updateState)(state);
    const validate = curry(validators.changes)(initial);
    const getChanges = curry(extractChanges)(state);
  
    function getState(selector = state => state) {
      validators.selector(selector);
      return selector(state.current);
    }
  
    function setState(causedChanges) {
      compose(
        didUpdate,
        update,
        validate,
        getChanges,
      )(causedChanges);
    }
  
    return [getState, setState];
  }
  
  function extractChanges(state, causedChanges) {
    return isFunction(causedChanges)
      ? causedChanges(state.current)
      : causedChanges;
  }
  
  function updateState(state, changes) {
    state.current = { ...state.current, ...changes };
  
    return changes;
  }
  
  function didStateUpdate(state, handler, changes) {
    isFunction(handler)
      ? handler(state.current)
      : Object.keys(changes)
          .forEach(field => handler[field]?.(state.current[field]));
  
    return changes;
  }
  
  export { create };   
`;
