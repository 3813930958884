import { memo } from 'react';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { navVerticalConfig } from './config';

import DocList from './list';
import { Box } from '@mui/material';
import AddEditSidebar from './AddEditSidebar';

// ----------------------------------------------------------------------

function SidebarItems({ config, sx, data, hideSubHeader = false, ...other }) {
  const _config = navVerticalConfig(config);

  return (
    <Stack sx={sx} {...other}>
      <List
        disablePadding
        sx={{
          px: 2,
          ...(_config?.isCompact && {
            px: 1,
            py: 1,
          }),
        }}
      >
        {data?.map((item, index) => (
          <DocList
            key={index + item.path}
            data={item}
            depth={1}
            hasChild={!!item.children}
            config={{ ..._config, step: index + 1 }}
          />
        ))}

        <Box component={'li'} my={2}>
          <AddEditSidebar />
        </Box>
      </List>
    </Stack>
  );
}

export default memo(SidebarItems);
