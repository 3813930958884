import { DevicesOtherRounded, ShareRounded } from '@mui/icons-material';
import { Button, ButtonGroup, CircularProgress, IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import { usePlaygroundContext } from 'src/context/PlaygroundContext';

export default function CodingBar() {
  const { code, selectedLanguage, handleUploadCode } = usePlaygroundContext();
  const [isWait, setIsWait] = useState(false);

  // const handleRunCode = async () => {
  //   try {
  //     const resp = await axiosInstance.post(process.env.REACT_APP_API_URL + '/code/run', {
  //       code: code,
  //       stdin: '',
  //       lang: selectedLanguage,
  //       options: {
  //         upload: false,
  //         logs: false,
  //       },
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     enqueueSnackbar(
  //       error.response?.data?.message || error.response?.statusText || 'an error occured',
  //       { variant: 'error' }
  //     );
  //   }
  // };

  const handleUploadToDevice = async () => {
    setIsWait(true);
    await handleUploadCode();
    setIsWait(false);
  };

  return (
    <Stack direction="row" spacing={2} width={1} mx={2}>
      <Stack
        sx={{
          width: '100%',
          borderRadius: 1,
          alignItems: 'center',
        }}
      >
        <Stack direction="row" spacing={1}>
          <Stack direction="row" spacing={2} width={1} alignItems={'center'}>
            <ButtonGroup
              variant="text"
              sx={{
                bgcolor: 'background.default',
              }}
              size="small"
            >
              {/* <Button
                color="inherit"
                sx={{ px: 3.6 }}
                onClick={handleRunCode}
                startIcon={<PlayArrowRounded />}
              >
                Run
              </Button> */}
              <Button
                color="success"
                sx={{ px: 2.7 }}
                onClick={handleUploadToDevice}
                startIcon={isWait ? <CircularProgress size={14} /> : <DevicesOtherRounded />}
              >
                Upload to Device
              </Button>
            </ButtonGroup>
          </Stack>
          <IconButton color="default">
            <ShareRounded fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
}
