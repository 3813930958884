import { Box, useTheme } from '@mui/material';

export const CustomDot = ({ color, sx, ...props }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: 12,
        height: 12,
        borderRadius: '50%',
        bgcolor: theme.palette[color || 'primary'].main,
        ...sx,
      }}
      {...props}
    />
  );
};
