import { useState, useEffect, useCallback } from 'react';
import Collapse from '@mui/material/Collapse';
import { usePathname } from 'src/routes/hooks';
import { useActiveLink } from 'src/routes/hooks/use-active-link';
import DocItem from './item';
import { Box } from '@mui/material';
import AddEditSidebar from './AddEditSidebar';
import { paths } from 'src/routes/paths';
import { useResourceHubContext } from 'src/context/ResourceHubContext';

// ----------------------------------------------------------------------

export default function DocList({ data, depth, hasChild, config }) {
  const pathname = usePathname();
  const { mode, resourceHub } = useResourceHubContext();

  const active = useActiveLink(
    `${paths.resourcehub.details(resourceHub?._id)}/${data.path}`,
    hasChild
  );

  const isChildActive = () => {
    let linkActiveStatus = false;
    data?.children?.forEach((child) => {
      if (`${paths.resourcehub.details(resourceHub?._id)}/${child?.path}` === pathname) {
        linkActiveStatus = true;
        return linkActiveStatus;
      }
    });
    return linkActiveStatus;
  };

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <DocItem
        item={data}
        depth={depth}
        open={isChildActive() || open}
        active={active}
        onClick={handleToggle}
        config={config}
      />
      {hasChild && !config.isCompact && (
        <Collapse
          in={isChildActive() || open}
          unmountOnExit
          sx={{
            pl: 3,
            '.mnl__nav__ul': {
              position: 'relative',
              paddingLeft: 1.5,
              '&:before': {
                top: 0,
                left: 0,
                width: '2px',
                content: '""',
                position: 'absolute',
                bottom: `calc(32px - 2px - (12px/2))`,
                backgroundColor: 'grey.300',
              },
            },
          }}
        >
          <Box
            className="mnl__nav__ul"
            component={'ul'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '2px',
              margin: 0,
              padding: 0,
              listStyleType: 'none',
            }}
          >
            {data?.children?.map((item) => (
              <Box component={'li'} key={item.title + item.path}>
                <DocList data={item} depth={depth + 1} hasChild={!!item.children} config={config} />
              </Box>
            ))}
            <Box className="finddjdsjdsgjb" component={'li'}>
              <AddEditSidebar parentId={data?._id} />
            </Box>
          </Box>
        </Collapse>
      )}
    </>
  );
}
