// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 50,
  H_DESKTOP: 70,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const PLAYGROUND_HEADER = {
  H_MOBILE: 30,
  H_DESKTOP: 50,
  H_DESKTOP_OFFSET: 50 - 16,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};

export const RESOURCES_NAV = {
  W_VERTICAL: 280 * 0.8,
  W_MINI: 88 * 0.8,
};
