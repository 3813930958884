import { Grid } from '@mui/material';
import React from 'react';

export default function ThreeColumns({ content1, content2, content3 }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {content1}
      </Grid>
      <Grid item xs={4}>
        {content2}
      </Grid>
      <Grid item xs={4}>
        {content3}
      </Grid>
    </Grid>
  );
}
