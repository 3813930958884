import { CloseSharp } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

export default function MiniTabs({ sx, tabs, value, onChange, onClose, children, ...props }) {
  const theme = useTheme();
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} {...props}>
      <Stack direction="row" spacing={1} p={0.5}>
        {tabs &&
          tabs?.length > 0 &&
          tabs?.map((tab, index) => (
            <Button
              key={index}
              size="small"
              onClick={() => onChange(tab.value)}
              startIcon={tab?.icon && tab?.icon}
              sx={{
                px: 2,
                backgroundColor:
                  tab?.value === value
                    ? theme.palette.background.default
                    : theme.palette.background.paper,
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                },
                ...sx,
              }}
              endIcon={onClose && <CloseAction onClick={() => onClose(tab.value)} />}
            >
              <Typography color={tab.value === value ? 'textPrimary' : 'textSecondary'}>
                <small>{tab.label}</small>
              </Typography>
            </Button>
          ))}
      </Stack>
      {children}
    </Stack>
  );
}

const CloseAction = ({ onClick }) => {
  return (
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <CloseSharp fontSize="1rem" />
    </IconButton>
  );
};
