export const splitArray = (array, size = 2) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

// objec to array as [{key: value}, {key: value}]
export const objectToArray = (object) => {
  return Object.keys(object).map((key) => ({ key, value: object[key] }));
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getErrorMessage = (error) => {
  return (
    error.response?.data?.message ||
    error.response?.statusText ||
    error?.message ||
    'An unknown error occured'
  );
};

export const generateSlug = (str) => {
  return str
    ?.toLowerCase()
    ?.trim() // Remove white spaces from start and end
    ?.replace(/\s+/g, ' ')
    ?.replace(/[^a-zA-Z0-9 ]/g, '') // Remove special characters
    ?.replace(/\s+/g, '-'); // Replace spaces with -
};

export function getImageUrl(image) {
  return image?.startsWith('http')
    ? image
    : image
      ? `${process.env.REACT_APP_DO_SPACE_URL}${image}?${Math.random()}`
      : '';
}

export const generateRandomString = async (length = 8) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

export const getColorByPercent = (percent) => {
  if (!percent)
    return {
      color: 'error.main',
      colorCode: '#f44336',
      themeColor: 'error',
    };

  if (percent < 10)
    return {
      color: 'secondary.main',
      colorCode: '#9c27b0',
      themeColor: 'secondary',
    };

  if (percent < 25)
    return {
      color: 'error.main',
      colorCode: '#f44336',
      themeColor: 'error',
    };

  if (percent < 50)
    return {
      color: 'warning.main',
      colorCode: '#ff9800',
      themeColor: 'warning',
    };

  if (percent < 75)
    return {
      color: 'info.main',
      colorCode: '#03a9f4',
      themeColor: 'info',
    };

  return {
    color: 'success.main',
    colorCode: '#4caf50',
    themeColor: 'success',
  };
};
