// ----------------------------------------------------------------------

import SvgColor from 'src/components/svg-color';
import { paths } from 'src/routes/paths';

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  purchases: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  resourcehub: icon('ic_resourcehub'),
  stocks: icon('ic_sell'),
  devices: icon('ic_devices'),
  question: icon('ic_question'),
  quiz: icon('ic_quiz'),
  settings: icon('ic_settings'),
};

const commonItems = [
  // ACCOUNT
  {
    title: 'Account',
    path: paths.dashboard.account.root,
    icon: ICONS.settings,
    children: [
      { title: 'profile', path: paths.dashboard.account.root },
      { title: 'settings', path: paths.dashboard.account.settings },
      { title: 'preferences', path: paths.dashboard.account.preferences },
    ],
  },
];

export const navItemsByAccountType = {
  admin: [
    // OVERVIEW
    // ----------------------------------------------------------------------
    {
      subheader: 'overview',
      items: [
        {
          title: 'analytics',
          path: paths.dashboard.root,
          icon: ICONS.dashboard,
        },

        {
          title: 'purchases',
          path: paths.dashboard.general.purchases,
          icon: ICONS.purchases,
        },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // REGISTERED DEVICES
        {
          title: 'registered devices',
          path: paths.dashboard.stocks.root,
          icon: ICONS.stocks,
        },

        // RESOURCE HUB
        {
          title: 'resource hub',
          path: paths.dashboard.resourcehub.root,
          icon: ICONS.resourcehub,
        },

        // DEVICES
        {
          title: 'devices',
          path: paths.dashboard.devices.root,
          icon: ICONS.devices,
        },

        // PRODUCT
        {
          title: 'product',
          path: paths.dashboard.product.root,
          icon: ICONS.product,
        },

        // USER
        {
          title: 'users',
          path: paths.dashboard.user.root,
          icon: ICONS.user,
        },

        // TODO: ORDER
        // {
        //   title: 'Sales',
        //   path: paths.dashboard.order.root,
        //   icon: ICONS.order,
        //   children: [
        //     { title: 'Orders', path: paths.dashboard.order.root },
        //     { title: 'In-Cart', path: paths.dashboard.cart.root },
        //     // { title: 'In-Cart', path: paths.dashboard.user.cards },
        //     // { title: 'Plans', path: paths.dashboard.user.cards },
        //   ],
        // },

        ...commonItems,
      ],
    },

    // CONTENT MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'Content Management',
      items: [
        // Questions content
        {
          title: 'Questions',
          path: paths.dashboard.questions.root,
          icon: ICONS.question,
        },
        // Forms content
        {
          title: 'Forms',
          path: paths.dashboard.forms.root,
          icon: ICONS.quiz,
        },
        // EXAMPLE content
        {
          title: 'Examples',
          path: paths.dashboard.examples.root,
          icon: ICONS.folder,
        },

        // BLOG
        {
          title: 'Blogs',
          path: paths.dashboard.post.root,
          icon: ICONS.blog,
        },
      ],
    },
  ],
  user: [
    // OVERVIEW
    // ----------------------------------------------------------------------
    {
      subheader: 'overview',
      items: [
        {
          title: 'analytics',
          path: paths.dashboard.root,
          icon: ICONS.dashboard,
        },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // RESOURCE HUB
        {
          title: 'resource hub',
          path: paths.dashboard.resourcehub.root,
          icon: ICONS.resourcehub,
        },

        // DEVICES
        {
          title: 'devices',
          path: paths.dashboard.registeredDevices.root,
          icon: ICONS.devices,
        },

        // ORDER
        {
          title: 'orders',
          path: paths.dashboard.order.root,
          icon: ICONS.order,
        },

        ...commonItems,
      ],
    },
  ],
};
