import { styled } from '@mui/material';
import { BlocklyWorkspace } from 'react-blockly';

export const StyledBlocklyWorkspace = styled(BlocklyWorkspace)(({ theme }) => ({
  '& .blocklyFlyoutBackground': {
    fill: theme.palette.background.paper,
    opacity: 0.8,
  },
  '& .blocklySvg': {
    backgroundColor: theme.palette.background.terminal,
  },
}));
