import { Stack, Typography } from '@mui/material';
import React from 'react';
import { CustomDot } from 'src/components/custom-styled/CustomDot';
import Label from 'src/components/label/label';

export default function DeviceStatusCard({ connected, title, subtitle }) {
  return (
    <div>
      <Stack alignItems={'flex-start'} width={1} px={2}>
        <Typography variant="subtitle1">{title}</Typography>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Label
            color={connected ? 'success' : 'error'}
            startIcon={<CustomDot color={connected ? 'success' : 'error'} />}
          >
            {subtitle}
          </Label>
        </Stack>
      </Stack>
    </div>
  );
}
