import { Stack, Typography } from '@mui/material';
import React from 'react';

export default function TextContent({ ...content }) {
  return (
    <Stack>
      {content?.title && <Typography variant="h4">{content?.title}</Typography>}
      {content?.subtitle && (
        <Typography variant="subtitle1" color="text.secondary">
          {content?.subtitle}
        </Typography>
      )}
      {content?.description && (
        <Typography variant="body1" mt={content?.subtitle ? 3 : 1}>
          <div dangerouslySetInnerHTML={{ __html: content?.description }} />
        </Typography>
      )}
    </Stack>
  );
}
