import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const OverviewDashboardPage = lazy(() => import('src/pages/dashboard/dashboard'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const AddEditProduct = lazy(() => import('src/pages/dashboard/product/add-edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAddEditPage = lazy(() => import('src/pages/dashboard/user/add-edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogAddEditPostPage = lazy(() => import('src/pages/dashboard/post/add-edit'));
// EXAMPLE
const ExamplesListPage = lazy(() => import('src/pages/dashboard/examples/list'));
const ExamplesAddEditPage = lazy(() => import('src/pages/dashboard/examples/add-edit'));
// RESOURCE HUB
const ResourceHubsPage = lazy(() => import('src/pages/dashboard/resourcehub'));
const AddEditResourceHub = lazy(() => import('src/pages/dashboard/resourcehub/AddEditResourceHub'));
// DEVICES
const DevicesList = lazy(() => import('src/pages/dashboard/devices/list'));
const AddEditDevices = lazy(() => import('src/pages/dashboard/devices/add-edit'));
const DeviceDetails = lazy(() => import('src/pages/dashboard/devices/details'));
const RegisterDevice = lazy(() => import('src/pages/dashboard//devices/register-device'));
// SETTINGS
const SettingsPage = lazy(() => import('src/pages/dashboard/account/SettingsPage'));
const PreferencesPage = lazy(() => import('src/pages/dashboard/account/PreferencesPage'));
const ProfilePage = lazy(() => import('src/pages/dashboard/account/ProfilePage'));
// STOCKS
const StocksPage = lazy(() => import('src/pages/dashboard/stocks'));
// Questions
const QuestionsListPage = lazy(() => import('src/pages/dashboard/questions/list'));
const QuestionAddEditPage = lazy(() => import('src/pages/dashboard/questions/add-edit'));
const QuestionDetailsPage = lazy(() => import('src/pages/dashboard/questions/details'));
// FORMS
const FormsListPage = lazy(() => import('src/pages/dashboard/forms'));
const FormAddEditPage = lazy(() => import('src/pages/dashboard/forms/add-edit'));
const FormDetailsPage = lazy(() => import('src/pages/dashboard/forms/details'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      { element: <OverviewDashboardPage />, index: true },
      { path: 'purchases', element: <OverviewBookingPage /> },
      {
        path: 'users',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'add', element: <UserAddEditPage /> },
          { path: ':id', element: <UserProfilePage /> },
          { path: ':id/edit', element: <UserAddEditPage /> },
        ],
      },
      {
        path: 'stocks',
        children: [{ element: <StocksPage />, index: true }],
      },
      {
        path: 'resource-hub',
        children: [
          { element: <ResourceHubsPage />, index: true },
          { path: 'new', element: <AddEditResourceHub /> },
          { path: ':resourceHubId/edit', element: <AddEditResourceHub /> },
        ],
      },
      {
        path: 'devices',
        children: [
          { element: <DevicesList />, index: true },
          { path: 'new', element: <AddEditDevices /> },
          { path: ':deviceId', element: <DeviceDetails /> },
          { path: ':deviceId/edit', element: <AddEditDevices /> },
        ],
      },
      {
        path: 'd',
        children: [
          { element: <DevicesList />, index: true },
          { path: 'register', element: <RegisterDevice /> },
          { path: ':deviceId', element: <DeviceDetails /> },
        ],
      },
      {
        path: 'products',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <AddEditProduct /> },
          { path: ':id/edit', element: <AddEditProduct /> },
        ],
      },
      {
        path: 'orders',
        children: [
          { element: <OrderListPage />, index: true },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'cart',
        children: [
          { element: <OrderListPage />, index: true },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'questions',
        children: [
          { element: <QuestionsListPage />, index: true },
          { path: 'new', element: <QuestionAddEditPage /> },
          { path: ':questionId', element: <QuestionDetailsPage /> },
          { path: ':questionId/edit', element: <QuestionAddEditPage /> },
        ],
      },
      {
        path: 'forms',
        children: [
          { element: <FormsListPage />, index: true },
          { path: 'new', element: <FormAddEditPage /> },
          { path: ':formId', element: <FormDetailsPage /> },
          { path: ':formId/edit', element: <FormAddEditPage /> },
        ],
      },
      {
        path: 'examples',
        children: [
          { element: <ExamplesListPage />, index: true },
          { path: 'new', element: <ExamplesAddEditPage /> },
          { path: ':id/edit', element: <ExamplesAddEditPage /> },
        ],
      },
      {
        path: 'account',
        children: [
          { path: 'profile', element: <ProfilePage /> },
          { path: 'settings', element: <SettingsPage /> },
          { path: 'preferences', element: <PreferencesPage /> },
        ],
      },

      {
        path: 'blogs',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: ':slug', element: <BlogPostPage /> },
          { path: ':slug/edit', element: <BlogAddEditPostPage /> },
          { path: 'new', element: <BlogAddEditPostPage /> },
        ],
      },
    ],
  },
];
