import React from 'react';
import { alpha, Box, CircularProgress, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useResourceHubContext } from 'src/context/ResourceHubContext';
import { EditOutlined, HighlightOffOutlined } from '@mui/icons-material';
import { Confirm } from 'notiflix';

function ContentWrapper({ editContent, handleDelete, isWait, hideActions, children }) {
  const theme = useTheme();
  const { mode } = useResourceHubContext();
  return (
    <Box
      width={1}
      height={1}
      sx={{
        position: 'relative',
        ...(mode === 'edit' &&
          !hideActions && {
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 2,
            borderColor: theme.palette.background.paper,
            '&:hover': {
              borderColor: theme.palette.primary.main,
              '& .hover-menu': {
                display: 'flex',
              },
            },
          }),
      }}
    >
      {!hideActions && (
        <Stack
          direction={'row'}
          className="hover-menu"
          sx={{
            display: 'none',
            position: 'absolute',
            top: 0,
            right: theme.spacing(2),
            zIndex: 1,
            transform: 'translateY(-50%)',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 1.5,
            // py: 0.25,
            px: 1,
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: theme.palette.grey[300],
          }}
          elevation={2}
        >
          <IconButton onClick={editContent}>
            <EditOutlined
              sx={{
                color: theme.palette.text.primary,
                fontSize: 14,
              }}
            />
          </IconButton>
          <Tooltip title="Remove this section">
            <IconButton
              onClick={() => {
                Confirm.show(
                  'Confirmation',
                  'Upon deletion the layout will reset. Are you sure you want to delete this section?',
                  'Confirm',
                  'Cancel',
                  () => handleDelete(),
                  () => {},
                  {
                    width: '500px',
                    messageMaxLength: 1000,
                  }
                );
              }}
              sx={{
                color: theme.palette.secondary.main,
                '&:hover': {
                  color: theme.palette.error.main,
                },
              }}
            >
              <HighlightOffOutlined
                sx={{
                  fontSize: 14,
                }}
              />
            </IconButton>
          </Tooltip>
          {isWait && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: alpha(theme.palette.background.paper, 0.5),
              }}
            >
              <CircularProgress size={20} />
            </Box>
          )}
        </Stack>
      )}
      {children}
    </Box>
  );
}

export default ContentWrapper;
