import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import { CircularProgress, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx, ...other }) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <CircularProgress color="secondary" sx={{ width: 1, maxWidth: 360 }} />
        <Typography sx={{ color: 'text.secondary' }} my={2}>
          Please wait...
        </Typography>
      </Box>
    </Box>
  );
}

LoadingScreen.propTypes = {
  sx: PropTypes.object,
};
