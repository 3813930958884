export const resourceHubSamplesByType = {
  text: {
    title: 'Text Content',
    subtitle: 'This is a subtitle',
    description: 'This is a description of the text content.',
  },
  coding: {
    title: 'Coding Editor',
    language: 'Python',
    content: `
        #The try block will generate a NameError, because x is not defined:
try:
  print(x)
except NameError:
  print("Variable x is not defined")
except:
  print("Something else went wrong")
    `,
  },
  blockly: {
    title: 'Blockly',
    language: 'Python',
    content:
      '{"type":"controls_if","message0":"if %1 then %2 else %3","args0":[{"type":"input_value","name":"IF0"},{"type":"input_statement","name":"DO0"},{"type":"input_statement","name":"ELSE"}],"inputsInline":true,"previousStatement":null,"nextStatement":null,"colour":210,"tooltip":"","helpUrl":""}',
  },
  video: {
    title: 'Video',
    content: 'https://www.youtube.com/watch?v=EngW7tLk6R8',
  },
  question: {
    title: 'Question',
    content: 'https://www.youtube.com/watch?v=EngW7tLk6R8',
  },
  quiz: {
    title: 'Quiz',
    content: 'https://www.youtube.com/watch?v=EngW7tLk6R8',
  },
};
