import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Logo from 'src/components/logo';
import { Grid, Stack } from '@mui/material';
import { PLAYGROUND_HEADER } from '../config-layout';
import CodingBar from 'src/sections/CodingPlayground/CodingBar';
import { AccountPopover } from '../_common';

export default function Header() {
  return (
    <AppBar position="static" sx={{ height: PLAYGROUND_HEADER.H_DESKTOP }}>
      <Grid container sx={{ px: 2, alignItems: 'center' }}>
        <Grid
          item
          sx={{
            display: { xs: 'none', md: 'block' },
          }}
          xs={3}
        >
          <Stack direction={'row'} spacing={2}>
            <Logo sx={{ width: 30 }} />
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <CodingBar />
        </Grid>

        <Grid item xs={3}>
          <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
            <AccountPopover />
          </Stack>
        </Grid>
      </Grid>
    </AppBar>
  );
}
