import React from 'react';

import MonacoEditor from '@monaco-editor/react';
import { Divider, Stack } from '@mui/material';
import { EDITOR_OPTIONS, rTabs } from './options';
import EditorActions from './EditorActions';

const MonacoCodeEditor = ({ code, theme, language, handleChange, sx, ...rest }) => {
  function handleEditorWillMount(monaco) {
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.Latest,
      module: monaco.languages.typescript.ModuleKind.ES2015,
      allowNonTsExtensions: true,
      lib: ['es2018'],
    });
  }

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: 'background.default',
        ...sx,
      }}
    >
      {rest?.readOnly ? null : (
        <>
          {/* <EditorActions /> */}
          {/* <Divider /> */}
        </>
      )}
      <Stack sx={{ pt: 1.3 }}>
        <MonacoEditor
          theme={theme || 'vs-dark'}
          height={sx?.height || '100vh'}
          path={language}
          defaultValue={rTabs(code)}
          defaultLanguage={language}
          options={{
            ...EDITOR_OPTIONS,
            ...{
              readOnly: rest?.readOnly || false,
              minimap: { enabled: rest?.minimap === false ? false : true },
              lineNumbers: rest?.lineNumbers || 'on',
            },
          }}
          beforeMount={handleEditorWillMount}
          value={code}
          onChange={rest?.readOnly ? () => {} : (value) => handleChange(value)}
        />
      </Stack>
    </Stack>
  );
};

export default MonacoCodeEditor;
