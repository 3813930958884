import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import Header from './header';
import { HEADER } from '../config-layout';
import { RESOURCES_NAV as NAV } from '../config-layout';
import { useSettingsContext } from 'src/components/settings';
import { useSearchParams } from 'react-router-dom';
import { useResponsive } from 'src/hooks/use-responsive';
import ResourceHubSidebar from 'src/sections/Resourcehub/resource-hub-sidebar';

// ----------------------------------------------------------------------

export default function ResourceHubLayout({ children }) {
  const nav = useBoolean();
  const settings = useSettingsContext();
  const [searchParams] = useSearchParams();
  const isCompact = searchParams.has('compact');

  const lgUp = useResponsive('up', 'lg');

  const isNavMini = settings.themeLayout === 'mini';

  const renderNavVertical = <ResourceHubSidebar openNav={nav.value} onCloseNav={nav.onFalse} />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          marginTop: `${HEADER.H_DESKTOP}px`,
        }}
      >
        {renderNavVertical}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: 1,
            display: 'flex',
            flexDirection: 'column',
            py: 1,
            ...(lgUp && {
              px: 2,
              width: `calc(100% - ${NAV.W_VERTICAL}px)`,
              ...(isNavMini && {
                width: `calc(100% - ${NAV.W_MINI}px)`,
              }),
              ...(isCompact && {
                width: `calc(100% - ${NAV.W_MINI}px)`,
              }),
            }),
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}

ResourceHubLayout.propTypes = {
  children: PropTypes.node,
};
