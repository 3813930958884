import { useMemo } from 'react';
import { useSearchParams as _useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useSearchParams() {
  const [searchParams, setSearchParams] = _useSearchParams();

  return useMemo(() => {
    return {
      searchParams,
      setSearchParams,
      get: (key) => searchParams.get(key),
      set: (key, value) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(key, value);
        setSearchParams(newSearchParams);
      },
      remove: (key) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(key);
        setSearchParams(newSearchParams);
      },
    };
  }, [searchParams, setSearchParams]);
}
