import ListItemText from '@mui/material/ListItemText';
import Iconify from '../../../components/iconify';
import { StyledItem, StyledIcon } from './styles';
import { Box, IconButton } from '@mui/material';
import AddEditSidebar from './AddEditSidebar';
import { useEffect, useState } from 'react';
import { useResourceHubContext } from 'src/context/ResourceHubContext';
import { paths } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export default function DocItem({ item, open, depth, active, config, ...other }) {
  const { title, path, icon, children, disabled } = item;
  const { mode, resourceHub } = useResourceHubContext();
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  // get pathname from usePathname hook
  const pathname = usePathname();
  const [basePath, setBasePath] = useState('');

  useEffect(() => {
    const pathSplit = pathname.split('/');
    if (pathSplit[1] === 'learning') {
      setBasePath(paths.learning.details('hub', pathSplit[3]));
    } else {
      setBasePath(paths.resourcehub.details(resourceHub?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem
      disableGutters
      disabled={disabled}
      active={active}
      depth={depth}
      config={config}
      {...other}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        navigate(`${basePath}/${path}` + (mode ? `?mode=${mode}` : ''));
      }}
      // LinkComponent={RouterLink}
      // href={`${basePath}/${path}` + (mode ? `?mode=${mode}` : '')}
    >
      {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}
      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={config.isCompact ? config.step : title}
          primaryTypographyProps={{
            noWrap: true,
            color: 'text.primary',
            typography: 'body2',
            textTransform: 'capitalize',
            fontWeight: active ? 'fontWeightBold' : 'fontWeightSemiBold',
          }}
        />
      )}

      <Box>{hovered && <AddEditSidebar item={item} />}</Box>
      {(mode === 'edit' || (children && children?.length > 0 && !config.isCompact)) && (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            other.onClick();
          }}
          sx={{
            ml: 1,
            flexShrink: 0,
          }}
          aria-describedby={item._id}
        >
          <Iconify
            width={16}
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          />
        </IconButton>
      )}
    </StyledItem>
  );

  // Has child
  if (children && children.length > 0) {
    return renderContent;
  }

  // Default
  return (
    <Box
      onClick={() => {
        navigate(`${basePath}/${path}` + (mode ? `?mode=${mode}` : ''));
      }}
      // component={RouterLink}
      // to={`${paths.resourcehub.details(resourceHub?._id)}/${path}` + (mode ? `?mode=${mode}` : '')}
      underline="none"
      sx={{
        position: 'relative',
        ...(disabled && {
          cursor: 'default',
        }),
        ...(subItem && {
          display: 'flex',
          alignItems: 'center',
          '&:before': {
            left: 0,
            content: '""',
            position: 'absolute',
            width: '12px',
            height: '12px',
            transform: `translate(-12px, -4.8px)`,
            backgroundColor: 'grey.300',
            mask: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23efefef' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") 50% 50% / 100% no-repeat`,
          },
        }),
      }}
    >
      {renderContent}
    </Box>
  );
}
