import { CardContent, CardHeader, Stack, Typography } from '@mui/material';
import React from 'react';
import QuestionInputField from '../QuestionInputField';
import CorrectAnswer from '../CorrectAnswer';

export default function QuestionView({ action, question, showAnswer = false }) {
  return (
    <>
      <CardHeader
        title={
          <Typography variant="h6" component="div" minHeight={26} mb={1}>
            {question?.title || ''} {question?.required ? '*' : ''}
          </Typography>
        }
        action={action || null}
      />
      <CardContent sx={{ py: 0 }}>
        <QuestionInputField
          question={question}
          handleFormInput={() => {}}
          formInput={{}}
          setFormInput={() => {}}
        />
        {showAnswer && question?.correctAnswer && (
          <Stack mt={2}>
            <CorrectAnswer data={question} />
          </Stack>
        )}
      </CardContent>
    </>
  );
}
