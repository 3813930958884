import { enqueueSnackbar } from 'notistack';
import { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import axiosInstance from 'src/utils/axios';
import { getErrorMessage } from 'src/utils/helpers';

export const AuthContext = createContext({});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [searchParams] = useSearchParams();

  const saveUserInLocalHost = (data) => {
    localStorage.setItem('user', JSON.stringify(data?.user));
    localStorage.setItem('accessToken', JSON.stringify(data?.accessToken));
    localStorage.setItem('refreshToken', JSON.stringify(data?.refreshToken));
  };

  const redirectUser = (data) => {
    setTimeout(() => {
      window.location.replace(
        data?.requiresPasswordReset
          ? `${paths.dashboard.account.settings}?q=verify`
          : searchParams?.get('redirect_url')
            ? searchParams?.get('redirect_url')
            : paths.dashboard.root
      );
    }, 1000);
  };

  const login = async (obj) => {
    try {
      const resp = await axiosInstance.post('/auth/login', obj);
      if (resp.status === 200) {
        saveUserInLocalHost(resp?.data);
        if (resp?.data?.requiresPasswordReset) {
          localStorage.setItem(
            'requiresPasswordReset',
            JSON.stringify(resp.data?.requiresPasswordReset)
          );
          enqueueSnackbar('Please change your password', { variant: 'info' });
        } else {
          enqueueSnackbar('Login successfully', { variant: 'success' });
        }

        redirectUser(resp?.data);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  };

  const register = async (obj) => {
    try {
      const resp = await axiosInstance.post('/auth/register', obj);
      if (resp.status === 201) {
        enqueueSnackbar('Registered successfully', { variant: 'success' });
        saveUserInLocalHost(resp?.data);
        redirectUser(resp?.data);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        // methods
        login,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
