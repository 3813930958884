import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { Link } from 'react-router-dom';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

export default function SettingsButton({ sx }) {
  return (
    <Box
      transition={{
        duration: 12,
        ease: 'linear',
        repeat: Infinity,
      }}
    >
      <IconButton
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        aria-label="settings"
        component={Link}
        to={paths.dashboard.account.settings}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <Iconify icon="solar:settings-bold-duotone" width={24} />
      </IconButton>
    </Box>
  );
}

SettingsButton.propTypes = {
  sx: PropTypes.object,
};
