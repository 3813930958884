import { CardMedia } from '@mui/material';
import React from 'react';

export default function ImagePreview({ url, sx }) {
  return (
    <div>
      {/* <img
        src={type ? `/assets/customs/${type}.png` : url}
        alt=""
        style={{
          width: '100%',
          height: 'auto',
        }}
      /> */}
      <CardMedia component="img" image={url} alt={''} sx={{ objectFit: 'contain', ...sx }} />
    </div>
  );
}
