import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import { NavSectionHorizontal } from 'src/components/nav-section';
//
import { useNavData } from './config-navigation';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();

  const { user } = useMockedUser();

  const navData = useNavData();

  return (
    <Toolbar
      sx={{
        ...bgBlur({
          color: theme.palette.background.default,
        }),
      }}
    >
      <NavSectionHorizontal
        data={navData}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />
    </Toolbar>
  );
}

export default memo(NavHorizontal);
