import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import ResourceHubLayout from 'src/layouts/resourcehub';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import LearningHub from 'src/pages/learning';
import { LearningHubProvider } from 'src/context/LearningHubContext';
import { ResourceHubProvider } from 'src/context/ResourceHubContext';

// ----------------------------------------------------------------------

// OVERVIEW
const ResourceHub = lazy(() => import('src/pages/resource-hub'));
// ----------------------------------------------------------------------

export const learningRoutes = [
  {
    path: 'learning',
    element: (
      <LearningHubProvider>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </LearningHubProvider>
    ),
    children: [
      {
        path: 'hub', // resource hub
        element: (
          <ResourceHubProvider>
            <ResourceHubLayout>
              <Outlet />
            </ResourceHubLayout>
          </ResourceHubProvider>
        ),

        children: [
          {
            path: ':enrollmentId',
            element: <LearningHub />,
          },

          {
            path: ':enrollmentId/:moduleSlug',
            element: <LearningHub />,
          },
        ],
      },
      {
        path: 'c', // course
        element: <ResourceHub />,
      },
    ],
  },
];
