import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { Button, CardHeader, Container } from '@mui/material';
import { getImageUrl, splitArray } from 'src/utils/helpers';
import { Dashboard } from '@mui/icons-material';
import { useGlobalContext } from 'src/context/GlobalContext';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Dashboard',
    linkTo: paths.dashboard.root,
  },
  {
    label: 'Settings',
    linkTo: paths.dashboard.account.settings,
  },
  {
    label: 'Orders',
    linkTo: paths.dashboard.order.root,
  },
  {
    label: 'Change Password',
    linkTo: paths.dashboard.account.settings + '?tab=change-password',
  },
  {
    label: 'Contact Us',
    linkTo: paths.contact,
  },
  {
    label: 'Preferences',
    linkTo: paths.dashboard.account.preferences,
  },
];

const QuickActions = [
  {
    label: 'Profile',
    linkTo: paths.dashboard.account.profile,
    icon: Dashboard,
    color: 'warning',
  },
  {
    label: 'Resource Hub',
    linkTo: paths.dashboard.resourcehub.root,
    icon: Dashboard,
    color: 'error',
  },
  {
    label: 'Devices',
    linkTo: paths.dashboard.devices.root,
    icon: Dashboard,
    color: 'success',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { currentUser, logout } = useGlobalContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace(paths.auth.login);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 50,
          height: 50,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={getImageUrl(currentUser?.profileImage)}
          alt={currentUser?.firstName + '' + (currentUser?.lastName || '')}
          sx={{
            width: 40,
            height: 40,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ minWidth: 250 }}>
        <Box sx={{ pb: 2 }}>
          <CardHeader
            avatar={
              <Avatar
                src={getImageUrl(currentUser?.profileImage)}
                alt={currentUser?.firstName + ' ' + (currentUser?.lastName || '')}
                sx={{
                  width: 60,
                  height: 60,
                  border: (theme) => `solid 2px ${theme.palette.background.default}`,
                }}
              />
            }
            title={
              <Typography variant="h5" noWrap>
                {currentUser?.firstName + ' ' + (currentUser?.lastName || '')}
              </Typography>
            }
            subheader={
              <Typography variant="subtitle1" sx={{ color: 'text.secondary' }} noWrap>
                {currentUser?.email}
              </Typography>
            }
          />
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack>
          {splitArray(QuickActions, 3).map((options, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {options?.map((option, idx) => (
                <Box
                  key={idx}
                  width={1}
                  p={1}
                  my={1}
                  component={Button}
                  onClick={() => handleClickItem(option?.linkTo)}
                >
                  <Stack
                    direction="column"
                    mx={2}
                    my={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <option.icon color={option?.color} sx={{ fontSize: '2.6rem' }} />
                    <Typography
                      variant="caption"
                      component="div"
                      sx={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {option?.label}
                    </Typography>
                  </Stack>
                </Box>
              ))}
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {splitArray(OPTIONS, 2).map((options, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {options?.map((option, idx) => (
                <Button
                  key={idx}
                  fullWidth
                  sx={{
                    justifyContent: 'flex-start',
                    textTransform: 'none',
                  }}
                  size="large"
                  onClick={() => handleClickItem(option.linkTo)}
                >
                  {option.label}
                </Button>
              ))}
            </Stack>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}
