import { AddTwoTone, DriveFileRenameOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { Confirm } from 'notiflix';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import CustomTextField from 'src/components/custom-inputs/TextField';
import { useResourceHubContext } from 'src/context/ResourceHubContext';
import { LEARNING_SKILLS } from 'src/utils/constants/constants';
import { generateSlug } from 'src/utils/helpers';

export default function AddEditSidebar({ parentId, item }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { mode, resourceHub, resourceHubSidebar, handelAddEditSidebar } = useResourceHubContext();

  const [formInput, setFormInput] = useState({});
  const [isWait, setIsWait] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSave = async () => {
    if (formInput?.title?.length > 30) {
      enqueueSnackbar('Title should be less than 30 characters', { variant: 'warning' });
      return false;
    }

    setIsWait(true);

    if (!formInput?._id) {
      formInput.position = resourceHubSidebar?.length;
    }

    if (parentId) {
      formInput.parent = parentId;
    }

    formInput.path = generateSlug(formInput.title);
    await handelAddEditSidebar(formInput);

    setFormInput({});
    setIsWait(false);

    handleClose();
  };

  const handleAddRemoveSkills = (e) => {
    const { name, checked } = e.target;
    setFormInput((prev) => ({
      ...prev,
      categories: checked
        ? [...(formInput?.categories || []), name]
        : formInput?.categories?.filter((skill) => skill !== name),
    }));
  };

  return mode && mode === 'edit' ? (
    <Box
      sx={{
        position: 'relative',
        ...(!item &&
          parentId && {
            display: 'flex',
            alignItems: 'center',
            '&:before': {
              left: 0,
              content: '""',
              position: 'absolute',
              width: '12px',
              height: '12px',
              transform: `translate(-12px, -4.8px)`,
              backgroundColor: 'grey.300',
              mask: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none' viewBox='0 0 14 14'%3E%3Cpath d='M1 1v4a8 8 0 0 0 8 8h4' stroke='%23efefef' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") 50% 50% / 100% no-repeat`,
            },
          }),
      }}
    >
      <Box>
        {item ? (
          <IconButton
            size="small"
            onClick={(e) => {
              setFormInput(item);
              handleClick(e);
            }}
            aria-describedby={item._id}
          >
            <DriveFileRenameOutline fontSize="1rem" />
          </IconButton>
        ) : (
          <Button
            size="small"
            color="secondary"
            startIcon={<AddTwoTone />}
            onClick={handleClick}
            aria-describedby={'add-new'}
          >
            Add New
          </Button>
        )}
      </Box>

      {open && (
        <Popover
          id={item?._id || 'add-new'}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Stack spacing={2} sx={{ p: 2, minWidth: 400 }}>
            <Stack>
              <CustomTextField
                label="Name"
                value={formInput.title}
                name="title"
                onChange={(e) => setFormInput({ ...formInput, title: e.target.value })}
              />
              <FormHelperText
                sx={{
                  color: formInput.title?.length > 30 ? 'red' : 'secondary',
                }}
              >
                Max 30 characters
              </FormHelperText>
            </Stack>
            <Stack mb={4}>
              <Typography sx={{ mb: 2 }} variant="body1" color={'text.secondary'}>
                Skills (optional)
              </Typography>
              <FormGroup sx={{ flexDirection: 'row' }}>
                {(resourceHub?.categories || [])?.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        name={item}
                        onChange={handleAddRemoveSkills}
                        checked={formInput?.categories?.includes(item)}
                      />
                    }
                    label={LEARNING_SKILLS[item]?.label}
                    sx={{ minWidth: 200 }}
                  />
                ))}
              </FormGroup>
            </Stack>
            <Stack justifyContent={'space-between'} direction={'row'} spacing={2}>
              <Box flexGrow={1}>
                {formInput._id && formInput?.position !== 0 && (
                  <Button
                    color="error"
                    size="small"
                    onClick={async () => {
                      Confirm.show(
                        'Confirmation',
                        'Please note that All associated sections will be deleted as well. Are you sure you want to delete?',
                        'Confirm',
                        'Cancel',
                        async () => {
                          await handelAddEditSidebar({ _id: formInput._id, isDelete: true });
                          handleClose();
                        },
                        () => {},
                        {
                          width: '500px',
                          messageMaxLength: 1000,
                        }
                      );
                    }}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={isWait || !formInput.title}
                onClick={handleSave}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Popover>
      )}
    </Box>
  ) : null;
}
