import { FileDownloadTwoTone } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { getImageUrl } from 'src/utils/helpers';

export default function FilePreview({ file, height }) {
  const ext = typeof file === 'string' ? file.split('.').pop() : file?.name?.split('.').pop();
  // const isImage = ['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(ext);
  const fileUrl = typeof file === 'string' ? getImageUrl(file) : URL.createObjectURL(file);

  return (
    // <Stack
    //   sx={{
    //     width: height || '100px',
    //     height: height || '100px',
    //     border: '1px solid',
    //     borderColor: 'divider',
    //     cursor: 'pointer',
    //     p: 0.5,
    //     borderRadius: 2,
    //     '&:hover': {
    //       borderColor: 'primary.main',
    //     },
    //   }}
    //   justifyContent="center"
    //   alignItems="center"
    //   style={{ textDecoration: 'none' }}
    //   component="a"
    //   target="_blank"
    // >
    //   {isImage ? (
    //     <CardMedia component="img" image={fileUrl} alt={''} sx={{ objectFit: 'contain' }} />
    //   ) : (
    //     <>
    //       <FileDownloadTwoTone fontSize="small" />
    //       <b>{ext?.toUpperCase()}</b>
    //     </>
    //   )}
    // </Stack>
    <Button
      size="small"
      startIcon={<FileDownloadTwoTone fontSize="small" />}
      component="a"
      href={fileUrl}
      target="_blank"
      variant="outlined"
      sx={{
        px: 2,
        color: 'secondary.main',
        '&:hover': {
          color: 'primary.main',
        },
      }}
    >
      <b>{ext?.toUpperCase()}</b>
    </Button>
  );
}
