import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import { HEADER } from '../config-layout';
import { AccountPopover } from '../_common';
import { capitalize, Chip, Grid, Tooltip, Typography } from '@mui/material';
import { useResourceHubContext } from 'src/context/ResourceHubContext';
import { Link } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import { ArrowBackIos, KeyboardArrowLeft } from '@mui/icons-material';
import { useGlobalContext } from 'src/context/GlobalContext';
import { usePathname } from 'src/routes/hooks';
import { useEffect, useState } from 'react';
import LearningProgressCard from 'src/sections/learnings/cards/LearningProgressCard';
import DeviceStatusCard from 'src/sections/registered-devices/cards/DeviceStatusCard';
import { useLearningHubContext } from 'src/context/LearningHubContext';
// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const pathname = usePathname();
  const [whichPage, setWhichPage] = useState('');

  const settings = useSettingsContext();
  const { enrollment } = useLearningHubContext();
  const { mode, resourceHub } = useResourceHubContext();
  const { currentUser } = useGlobalContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const lgUp = useResponsive('up', 'lg');

  useEffect(() => {
    const pathSplit = pathname.split('/');
    setWhichPage(pathSplit[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={5}>
          <Stack py={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip title="Go to Dashboard">
                <IconButton
                  component={Link}
                  to={
                    currentUser?.accountType === 'admin'
                      ? paths.dashboard.resourcehub.root
                      : currentUser?.accountType === 'user'
                        ? paths?.dashboard?.devices?.root
                        : paths.dashboard.root
                  }
                >
                  <KeyboardArrowLeft />
                </IconButton>
              </Tooltip>
              <Stack
                sx={{
                  display: { xs: 'none', lg: 'block' },
                }}
              >
                <Stack spacing={1} direction={'row'}>
                  <Typography variant="h4">{resourceHub?.title || 'Resource Hub'}</Typography>
                  {mode && (
                    <Chip
                      label={capitalize(mode || '') + ' Mode'}
                      color={mode === 'edit' ? 'warning' : 'info'}
                      size="small"
                      sx={{
                        width: 'max-content',
                        fontSize: '0.6rem',
                      }}
                      variant="outlined"
                    />
                  )}
                </Stack>
                {resourceHub?._id && currentUser?.accountType === 'admin' && (
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      cursor: 'pointer',
                      color: theme.palette.text.secondary,
                    }}
                    component={Link}
                    to={`${paths.resourcehub.details(resourceHub?._id)}?mode=${mode === 'edit' ? 'preview' : 'edit'}`}
                  >
                    <small>Click here to {mode === 'edit' ? 'preview' : 'edit'}</small>
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={7}>
          <Stack
            flexGrow={1}
            direction="row"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1 }}
          >
            <Stack spacing={3} direction={'row'} alignItems="center">
              {whichPage === 'learning' && enrollment && (
                <>
                  <DeviceStatusCard
                    connected={enrollment?.connected || false}
                    title={enrollment?.title + ` (${enrollment?.device_id})`}
                    subtitle={enrollment?.connected || false ? 'Connected' : 'Offline'}
                  />

                  <LearningProgressCard />
                </>
              )}
              <AccountPopover />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_DESKTOP,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
      elevation={2}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 2 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
