import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Chart, { useChart } from 'src/components/chart';
import { Tooltip } from '@mui/material';

// ----------------------------------------------------------------------

export default function CircularChart({ title, total, color = 'primary', chart, sx, ...other }) {
  const theme = useTheme();

  const { series, options } = chart;

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: theme.palette[color].light },
          { offset: 100, color: theme.palette[color].main },
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '78%',
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 6,
            color: theme.palette.common.white,
            fontSize: other?.fontSize || theme.typography.subtitle2.fontSize,
          },
        },
      },
    },
    ...options,
  });

  return (
    <Tooltip title={title}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          ...sx,
        }}
        {...other}
      >
        <Chart
          type="radialBar"
          series={[series]}
          options={chartOptions}
          width={other?.width || 86}
          height={other?.height || 86}
        />
      </Stack>
    </Tooltip>
  );
}
