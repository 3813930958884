import { alpha, capitalize, Stack, Tooltip, useTheme } from '@mui/material';
import React from 'react';
import { resourceHubSamplesByType } from 'src/assets/samples/resource-hub-samples';
import ImagePreview from 'src/components/ContentPreview/ImagePreview';
import TextContent from 'src/components/ContentPreview/textContent';
import CustomCardWithLabel from 'src/components/custom-card-label';
import { LAYOUT_CONTENT_TYPES } from 'src/utils/constants/constants';

export default function ContentSamples({ onAddContent }) {
  const theme = useTheme();

  const resourceHubContentTypes = [
    ['text', 'video'],
    ['coding', 'blockly'],
    ['question', 'quiz'],
  ];

  const getContentPreview = ({ type, value }) => {
    if (type === 'text') {
      return <TextContent {...value} />;
    } else {
      return (
        <ImagePreview
          url={`/assets/customs/${type}.png`}
          sx={{
            width: '100%',
            height: '140px',
            objectFit: 'cover',
          }}
        />
      );
    }
  };

  return (
    <Stack spacing={4}>
      {resourceHubContentTypes.map((content, index) => (
        <Stack key={index} spacing={2} direction={'row'}>
          {content?.map((item, idx) => (
            <Tooltip
              title={`Click to add ` + (LAYOUT_CONTENT_TYPES[item] || capitalize(item || ''))}
              key={idx + 'r'}
            >
              <Stack
                spacing={2}
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  backgroundColor: alpha(theme.palette.background.default, 0.3),
                  borderRadius: 2,

                  '&:hover': {
                    backgroundColor: theme.palette.background.default,
                  },
                }}
                onClick={() =>
                  onAddContent({ type: item, ...(resourceHubSamplesByType[item] || {}) })
                }
              >
                <CustomCardWithLabel
                  sx={{
                    height: '100%',
                  }}
                  label={LAYOUT_CONTENT_TYPES[item] || capitalize(item || '')}
                >
                  <Stack
                    m={1}
                    sx={{
                      borderRadius: '10px',
                      overflow: 'hidden',
                    }}
                  >
                    {getContentPreview({ type: item, value: resourceHubSamplesByType[item] })}
                  </Stack>
                </CustomCardWithLabel>
              </Stack>
            </Tooltip>
          ))}
        </Stack>
      ))}
    </Stack>
  );
}
