import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import { SplashScreen } from 'src/components/loading-screen';
import { PlaygroundProvider } from 'src/context/PlaygroundContext';
import { SocketProvider } from 'src/context/SocketContext';
import PlaygroundLayout from 'src/layouts/playground';

// ----------------------------------------------------------------------

export const CodePlayground = lazy(() => import('src/pages/codeplayground'));
const Blockly = lazy(() => import('src/pages/blockly'));

// ----------------------------------------------------------------------

export const playgroundRoutes = [
  {
    path: 'playground',
    element: (
      <PlaygroundProvider>
        <SocketProvider>
          <PlaygroundLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </PlaygroundLayout>
        </SocketProvider>
      </PlaygroundProvider>
    ),
    children: [
      { path: 'blockly', element: <Blockly /> },
      { path: 'coding', element: <CodePlayground /> },
    ],
  },
];
