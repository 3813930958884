import { Stack, Typography } from '@mui/material';
import React, { useState, useMemo } from 'react';
import FilePreview from 'src/components/fileupload/FilePreview';
import { fDate } from 'src/utils/format-time';

export default function CorrectAnswer({ data, hideCaption, textMaxLenght }) {
  // Helper function to format the correct answer based on the question type
  const getAnswerText = useMemo(
    () => (answer) => {
      if (!answer) return '-';

      switch (data?.type) {
        case 'select':
        case 'checkbox':
        case 'radio': {
          const _answer = data?.options?.find((option) => option.value === answer)?.label;
          return _answer || '';
        }
        case 'date':
          return fDate(answer);
        case 'rating': {
          const rating = Number(answer);
          if (rating < 1 || rating > 5 || isNaN(rating)) {
            return 'Invalid Rating';
          }
          return '⭐'.repeat(rating);
        }
        default:
          return answer || '';
      }
    },
    [data]
  );

  // Component for handling long text display (show more/less)
  const CorrectAnswerText = ({ answer }) => {
    const [showFullText, setShowFullText] = useState(false);

    const correctAnswer = getAnswerText(answer);
    const isLongText = correctAnswer.length > (textMaxLenght || 300);
    const displayText = showFullText
      ? correctAnswer
      : correctAnswer.substring(0, textMaxLenght || 300);

    return (
      <Typography variant="subtitle2">
        {displayText}
        {isLongText && !showFullText && '... '}
        {isLongText && (
          <Typography
            variant="subtitle2"
            sx={{ color: 'primary.main', cursor: 'pointer' }}
            onClick={() => setShowFullText(!showFullText)}
            component="span"
          >
            <small>{showFullText ? ' Show less' : ' Show more'}</small>
          </Typography>
        )}
      </Typography>
    );
  };

  if (!data?.correctAnswer) return null;

  if (data?.type === 'file') {
    const _answers = Array.from(data?.correctAnswer || []);
    return (
      <Stack
        direction={
          _answers?.length > 4 || ['text', 'textarea'].includes(data?.type) ? 'column' : 'row'
        }
        spacing={1}
      >
        {!hideCaption && (
          <Typography variant="subtitle2" color="primary">
            <small>{_answers.length > 1 ? 'Correct Answers:' : 'Correct Answer:'}</small>
          </Typography>
        )}
        <Stack direction="row" flexWrap="wrap" gap={2}>
          {_answers?.map((answer, index) => (
            <FilePreview key={index} file={answer} height={'75px'} />
          ))}
        </Stack>
      </Stack>
    );
  }

  // Split answers in case of multiple correct answers
  const answers = ['date']?.includes(data?.type)
    ? [data?.correctAnswer]
    : data?.correctAnswer?.toString()?.split(',');

  return (
    <Stack
      direction={
        answers?.length > 4 || ['text', 'textarea'].includes(data?.type) ? 'column' : 'row'
      }
      spacing={1}
    >
      {!hideCaption && (
        <Typography variant="subtitle2" color="primary">
          <small>{answers.length > 1 ? 'Correct Answers:' : 'Correct Answer:'}</small>
        </Typography>
      )}
      <Stack direction="row" flexWrap="wrap" gap={0.5}>
        {answers?.map((answer, index) => (
          <Stack key={index} direction="row" alignItems="center" spacing={0.5}>
            <CorrectAnswerText answer={answer} />
            {answers.length > 1 && index !== answers.length - 1 && (
              <Typography variant="subtitle2" component="span">
                ,{' '}
              </Typography>
            )}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
