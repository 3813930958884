import React from 'react';
import { Box, CardContent, Divider, Drawer, Stack, Tooltip, Typography } from '@mui/material';
import SectionContent from '..';
import CustomCardWithLabel from 'src/components/custom-card-label';
import ContentSamples from '../contentSamples';
import TextContentEditor from './TextContentEditor';
import { LoadingButton } from '@mui/lab';
import { ReplayOutlined } from '@mui/icons-material';
import { Confirm } from 'notiflix';
import CodingBlockEditor from './CodingBlockEditor';
import BlocklyBlockEditor from './BlocklyBlockEditor';
import VideoBlockEditor from './VideoBlockEditor';
import QuizEditor from './QuizEditor';
import QuestionEditor from './QuestionEditor';

function SectionContentEditor({
  selectedContent,
  setSelectedContent,
  handleClose,
  handleSaveContent,
  isWait,
}) {
  const getContentEditor = ({ type }) => {
    let editor = null;

    switch (type) {
      case 'text':
        editor = (
          <TextContentEditor
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
          />
        );
        break;

      case 'coding':
        editor = (
          <CodingBlockEditor
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
          />
        );
        break;
      case 'blockly':
        editor = (
          <BlocklyBlockEditor
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
          />
        );
        break;
      case 'video':
        editor = (
          <VideoBlockEditor
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
          />
        );
        break;

      case 'quiz':
        editor = (
          <QuizEditor selectedContent={selectedContent} setSelectedContent={setSelectedContent} />
        );
        break;

      case 'question':
        editor = (
          <QuestionEditor
            selectedContent={selectedContent}
            setSelectedContent={setSelectedContent}
          />
        );
        break;

      default:
        editor = null;
        break;
    }
    return editor;
  };

  return (
    <Drawer anchor={'right'} open={true} onClose={handleClose}>
      <Box
        sx={{
          position: 'relative',
          width: 800,
          px: 1,
        }}
      >
        <CardContent>
          <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
            <Stack>
              <Typography variant="h5" component="h3">
                Content Editor
              </Typography>
              {selectedContent && (
                <Tooltip title="Remove and change content">
                  <Typography
                    sx={{
                      fontSize: 12,
                      cursor: 'pointer',
                      color: '#4493F8',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={async () => {
                      Confirm.show(
                        'Confirmation',
                        'The content will be removed upon proceeding, Are you sure to proceed?',
                        'Confirm',
                        'Cancel',
                        () => handleSaveContent('remove'),
                        () => {},
                        {
                          width: '500px',
                          messageMaxLength: 1000,
                        }
                      );
                    }}
                  >
                    <ReplayOutlined fontSize="0.7rem" sx={{ mr: 1 }} /> rewrite content
                  </Typography>
                </Tooltip>
              )}
            </Stack>
            {selectedContent && (
              <LoadingButton
                loading={isWait}
                color="success"
                variant="contained"
                onClick={() => handleSaveContent(null)}
              >
                Save
              </LoadingButton>
            )}
          </Stack>
          <Divider sx={{ my: 2 }} />
          {selectedContent ? (
            <Stack spacing={3}>
              <CustomCardWithLabel label="Preview">
                <Stack
                  m={selectedContent?.type === 'text' ? 2 : 0}
                  sx={{
                    maxHeight: 300,
                    overflow: 'auto',
                  }}
                >
                  <SectionContent {...selectedContent} />
                </Stack>
              </CustomCardWithLabel>
              {getContentEditor(selectedContent)}
            </Stack>
          ) : (
            <ContentSamples
              onAddContent={(content) => {
                setSelectedContent(content);
              }}
            />
          )}
        </CardContent>
      </Box>
    </Drawer>
  );
}

export default SectionContentEditor;
