import {
  Button,
  capitalize,
  Card,
  CardHeader,
  Chip,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import QuizQuestion from '../QuizQuestion';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'src/utils/helpers';
import { LoadingButton } from '@mui/lab';
import axiosInstance from 'src/utils/axios';
import { Replay } from '@mui/icons-material';
import QuizProgressStepper from '../QuizProgressStepper';

export default function QuizView({ form }) {
  const theme = useTheme();
  const [quizState, setQuizState] = useState({
    started: false,
    waiting: false,
    questions: [],
    currentQuestion: 0,
    finished: false,
  });

  const { started, waiting, questions, currentQuestion, finished } = quizState;

  const setQuizStatePartial = (newState) => setQuizState((prev) => ({ ...prev, ...newState }));

  const handleStartQuiz = async () => {
    setQuizStatePartial({ waiting: true });
    try {
      const resp = await axiosInstance.get(`/forms/${form?._id}`);
      if (resp?.status === 200) {
        setQuizStatePartial({ questions: resp.data?.questions || [], started: true });
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    } finally {
      setQuizStatePartial({ waiting: false });
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestion === questions.length - 1) {
      setQuizStatePartial({ finished: true });
      return;
    }
    setQuizStatePartial({ currentQuestion: currentQuestion + 1 });
  };

  const renderQuizContent = () => {
    if (!started) {
      return (
        <Typography variant="subtitle2" color="text.secondary">
          {form?.description || ''}
        </Typography>
      );
    }

    if (finished) {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Replay />
          <Typography variant="body2">Quiz completed</Typography>
        </Stack>
      );
    }

    if (questions.length === 0) {
      return <Typography variant="body2">No questions found</Typography>;
    }
    return <QuizQuestion question={questions[currentQuestion]} />;
  };

  const renderActionButton = () => {
    if (!started) {
      return (
        <LoadingButton
          loading={waiting}
          variant="contained"
          size="small"
          color="primary"
          onClick={handleStartQuiz}
        >
          Start Quiz
        </LoadingButton>
      );
    }
    return (
      <LoadingButton
        loading={waiting}
        variant="contained"
        size="small"
        color="primary"
        onClick={handleNextQuestion}
        disabled={currentQuestion === questions.length}
      >
        {currentQuestion === questions.length - 1 ? 'Finish' : 'Next'}
      </LoadingButton>
    );
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={form?.title || ''}
        action={
          <Chip
            size="small"
            variant="outlined"
            color="primary"
            label={capitalize(form?.type || '')}
            sx={{ borderRadius: 5 }}
          />
        }
      />
      {/* <Stack mx={2} mt={2}>
        <Card
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <QuizProgressStepper activeStep={currentQuestion} totalSteps={questions.length || 0} />
        </Card>
      </Stack> */}
      <Card
        sx={{ backgroundColor: theme.palette.background.default, p: 2, m: 2 }}
        variant="outlined"
      >
        <QuizProgressStepper activeStep={currentQuestion} totalSteps={questions.length || 0} />
        <Stack sx={{ minHeight: started ? 300 : 100 }} mt={4}>
          {renderQuizContent()}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Stack>
            {finished && (
              <Button
                size="small"
                color="primary"
                startIcon={<Replay fontSize="small" />}
                onClick={() =>
                  setQuizStatePartial({
                    currentQuestion: 0,
                    started: true,
                    waiting: false,
                    finished: false,
                  })
                }
              >
                Give it another try
              </Button>
            )}
          </Stack>
          {renderActionButton()}
        </Stack>
      </Card>
    </Card>
  );
}
