import React from 'react';
import MonacoCodeEditor from 'src/components/MonacoCodeEditor';

export default function CodeBlock({ sx, ...props }) {
  return (
    <MonacoCodeEditor
      code={props?.code || ''}
      theme={props?.theme || 'vs-dark'}
      language={props?.language || 'python'}
      handleChange={props?.handleChange || (() => {})}
      readOnly={true}
      minimap={false}
      lineNumbers="off"
      sx={sx}
    />
  );
}
