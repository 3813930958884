import React from 'react';
import { Box, Card } from '@mui/material';
import TextContent from 'src/components/ContentPreview/textContent';
import { useResourceHubContext } from 'src/context/ResourceHubContext';
import CodeBlocks from 'src/components/ContentPreview/CodeBlocks';
import BlocklyPreview from 'src/components/ContentPreview/BlocklyPreview';
import VideoPreview from 'src/components/ContentPreview/VideoPreview';
import QuestionView from 'src/sections/questions/views/QuestionView';
import QuizView from 'src/sections/quiz/views/QuizView';

export default function SectionContent({
  type,
  contents = [],
  form = {},
  question = {},
  ...textContent
}) {
  const { mode } = useResourceHubContext();

  if (!type) {
    return mode === 'edit' ? (
      <Card
        key={mode} // Adding key here helps force re-render when mode changes
        sx={{
          width: '100%',
          height: '100%',
          minHeight: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        elevation={0}
      >
        {textContent.title || 'Content goes here.'}
      </Card>
    ) : null;
  }

  return (
    <Box key={`${type}-${contents.length}`}>
      {/* Force re-render when type or contents change */}
      {(() => {
        switch (type) {
          case 'text':
            return (
              <Box sx={{ px: mode === 'edit' ? 1 : 0 }}>
                <TextContent {...textContent} />
              </Box>
            );
          case 'coding':
            return <CodeBlocks codes={contents} />;
          case 'blockly':
            return <BlocklyPreview codes={contents} />;
          case 'video':
            return (
              <VideoPreview
                item={contents?.[0]?.content || ''}
                type={contents?.[0]?.language || ''}
              />
            );
          case 'quiz':
            return <QuizView form={form} />;
          case 'question':
            return (
              <QuestionView
                question={question}
                handleFormInput={() => {}}
                formInput={{}}
                setFormInput={() => {}}
                showAnswer={false}
              />
            );
          default:
            return null;
        }
      })()}
    </Box>
  );
}
