import { Button, Card, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { StyledBlocklyWorkspace } from 'src/components/custom-styled/StyledBlocklyWorkspace';
import MiniTabs from 'src/components/custom-tabs/MiniTabs';
import { useLearningHubContext } from 'src/context/LearningHubContext';
import { usePathname } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

export default function BlocklyPreview({ codes }) {
  const [code, setCode] = useState('');

  useEffect(() => {
    setCode(codes[0]?.content);
  }, [codes]);

  const pathname = usePathname();
  const [whichPage, setWhichPage] = useState('');
  const { enrollment } = useLearningHubContext();

  useEffect(() => {
    const pathSplit = pathname.split('/');
    setWhichPage(pathSplit[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const updateXYAttributes = (xmlString) => {
    // Use a regular expression to find the first x="value" and y="value"
    xmlString = xmlString?.replace(/x="[^"]+"/, 'x="10"'); // Replace the first x="value"
    xmlString = xmlString?.replace(/y="[^"]+"/, 'y="10"'); // Replace the first y="value"

    return xmlString;
  };

  const codeActions = (
    <Stack mx={1}>
      <Button
        color="primary"
        variant="contained"
        size="small"
        component={Link}
        to={paths.playground.blockly}
      >
        Try it
      </Button>
    </Stack>
  );

  return (
    <Card sx={{ position: 'relative', minHeight: 100 }} variant={'outlined'}>
      {code?.length === 0 ? (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            direction: 'column',
            minHeight: 100,
          }}
        >
          No Blockly code provided
        </Stack>
      ) : (
        <>
          {whichPage === 'learning' && enrollment && <MiniTabs>{codeActions}</MiniTabs>}
          <Stack
            className="blockly"
            sx={{
              height: 'calc(40vh - 100px)',
            }}
          >
            <Stack className="pane-content">
              <StyledBlocklyWorkspace
                toolboxConfiguration="<xml></xml>"
                initialXml={updateXYAttributes(code || '<xml></xml>')}
                className="blockly-workspace"
                workspaceConfiguration={{
                  readOnly: true,
                  scrollbars: true,
                }}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Card>
  );
}
