import { useSnackbar } from 'notistack';
import { useState, createContext, useEffect, useContext } from 'react';
import axiosInstance from 'src/utils/axios';

export const GlobalContext = createContext({});

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

export const GlobalProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  // ----------------------------------
  const [currentUser, setCurrentUser] = useState(null);
  const [globalLoading, setGlobalLoading] = useState('');
  const [metadata, setMetadata] = useState({
    categories: [],
    projects: [],
    tags: [],
  });
  // device_id, serial_number, sku, barcode, title, (connected: frontend based only)
  const [connectedDevices, setConnectedDevices] = useState(null);

  useEffect(() => {
    handleCurrentUser();
  }, []);

  const handleCurrentUser = () => {
    if (localStorage.getItem('user')) {
      setCurrentUser(JSON.parse(localStorage.getItem('user')));
    } else {
      setCurrentUser(null);
    }
  };

  const handleGetMetadata = async ({ items }) => {
    try {
      const _metadata = { ...metadata };
      // items that are not already fetched
      const _itemsToFetch = [];

      // if all items are already fetched, return
      for (let item of items) {
        if (!metadata[item] || metadata[item].length === 0) {
          _itemsToFetch.push(item);
        }
      }

      // if all items are already fetched, return
      if (_itemsToFetch.length === 0) {
        return metadata;
      }

      // fetch items
      const resp = await axiosInstance.get('/metadata', {
        params: { items: _itemsToFetch },
      });

      if (resp?.status === 200) {
        // change only the items that were fetched
        for (let item of _itemsToFetch) {
          _metadata[item] = resp.data[item];
        }
        setMetadata(_metadata);
      }

      return _metadata;
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        error.response?.data?.message || error.response?.statusText || 'an error occured',
        { variant: 'error' }
      );

      return metadata;
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('requiresPasswordReset');
      enqueueSnackbar('Logged out successfully');
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        error.response?.data?.message || error.response?.statusText || 'an error occured',
        { variant: 'error' }
      );
    }
  };

  // ------------------------- CONNECT A DEVICE -------------------------
  const handleConnectToDevice = (reqObj) => {
    // if device_id or serial_number ID is provided
    // if None of them is provided
    setConnectedDevices([]);
  };

  return (
    <GlobalContext.Provider
      value={{
        // state
        sidebarToggle,
        currentUser,
        globalLoading,
        metadata,
        connectedDevices,

        // setters
        setConnectedDevices,

        // methods
        toggleSidebar,
        closeSidebar,
        handleCurrentUser,
        logout,
        setGlobalLoading,
        handleGetMetadata,
        handleConnectToDevice,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
