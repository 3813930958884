import { CodeOutlined, OndemandVideoOutlined, SubtitlesOutlined } from '@mui/icons-material';

export const PAGE_LIMIT = 10;

export const TEMP_DEVICE_IMAGE =
  'https://img.freepik.com/premium-photo/urban-exploration-small-scale-mini-rc-car-adventures-city_985323-17773.jpg';

export const LAYOUT_TYPES = {
  single: 'Single Column',
  double: 'Two Columns',
  triple: 'Three Columns',
  twoByOne: 'One by Two Columns (1:2)',
  oneByTwo: 'Two by One Columns (2:1)',
};

export const LEARNING_SKILLS = {
  ai: { label: 'Artificial Intelligence' },
  ml: { label: 'Machine Learning' },
  arduino: { label: 'Arduino' },
  iot: { label: 'Internet of Things' },
  cybersecurity: { label: 'Cyber Security' },
  robotics: { label: 'Robotics' },
  electronics: { label: 'Electronics' },
};

export const ParsedOutputTypes = {
  data: {
    color: 'info',
    label: 'Data',
  },
  response: {
    color: 'success',
    label: 'Response',
  },
  broadcast: {
    color: 'warning',
    label: 'Broadcast',
  },
};

export const CONTENT_TYPES = {
  blockly: {
    label: 'Blockly',
    icon: SubtitlesOutlined,
    iconify: 'carbon:arrange-horizontal',
    color: 'success',
  },
  coding: {
    label: 'Coding',
    icon: CodeOutlined,
    iconify: 'tabler:code-dots',
    color: 'info',
  },
  video: {
    label: 'Video',
    icon: OndemandVideoOutlined,
    iconify: 'ph:video-light',
    color: 'warning',
  },
};

export const LAYOUT_CONTENT_TYPES = {
  text: 'Content only',
  quiz: 'Quiz',
  ...Object.values(CONTENT_TYPES).reduce((acc, { label }) => {
    acc[label] = label;
    return acc;
  }, {}),
};

export const ORDER_STATUS = [
  { value: 'pending', label: 'Pending' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
];

export const BLOG_STATUS = {
  draft: {
    label: 'Draft',
    color: 'secondary',
    value: 'draft',
  },
  published: {
    label: 'Published',
    color: 'success',
    value: 'published',
  },
};

export const ACCOUNT_TYPES = ['admin', 'triager', 'user'];

export const SOCIAL_LINKS = {
  github: {
    label: 'Github',
    icon: 'eva:github-fill',
    color: '#000000',
  },
  facebook: {
    label: 'Facebook',
    icon: 'eva:facebook-fill',
    color: '#1877F2',
  },
  instagram: {
    label: 'Instagram',
    icon: 'ant-design:instagram-filled',
    color: '#DF3E30',
  },
  linkedin: {
    label: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#006097',
  },
  twitter: {
    label: 'Twitter',
    icon: 'eva:twitter-fill',
    color: '#1C9CEA',
  },
};
