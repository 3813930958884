import React, { createContext, useContext, useEffect, useState } from 'react';
import socketio from 'socket.io-client';

const appBaseUrl = process.env.REACT_APP_SOCKET_URL;

const getSocket = (token) => {
  return socketio(appBaseUrl, {
    withCredentials: true,
    auth: {
      token: token,
    },
    transports: ['websocket'],
  });
};

const SocketContext = createContext({
  socket: null,
});

const useSocket = () => useContext(SocketContext);

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    setSocket(getSocket(accessToken));
  }, []);

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export { SocketProvider, useSocket };
