import { Stack, Grid } from '@mui/material';
import React from 'react';

export default function OneByTwoColumns({ content1, content2, content3 }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Stack spacing={2}>
          <Stack>{content2}</Stack>
          <Stack>{content3}</Stack>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        {content1}
      </Grid>
    </Grid>
  );
}
