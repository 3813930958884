import 'src/utils/highlight';
import ReactQuill from 'react-quill';
// @mui
import { alpha } from '@mui/material/styles';
//
import { StyledEditor } from './styles';
import Toolbar, { formats } from './toolbar';
import { useMemo, useState } from 'react';

// ----------------------------------------------------------------------

export default function QuillEditor({
  id = 'minimal-quill',
  error,
  simple = false,
  helperText,
  placeholder,
  sx,
  ...other
}) {
  const [toolbarRef, setToolbarRef] = useState();

  const modules = useMemo(() => {
    const config = {
      toolbar: {
        container: toolbarRef,
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      syntax: true,
      clipboard: {
        matchVisual: false,
      },
    };

    return config;
  }, [toolbarRef]);

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            '& .ql-editor': {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <Toolbar ref={setToolbarRef} isSimple={simple} />

        {!!toolbarRef && (
          <ReactQuill
            modules={modules}
            formats={formats}
            placeholder={placeholder || 'Write something awesome...'}
            {...other}
          />
        )}
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
