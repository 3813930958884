// ----------------------------------------------------------------------

const ROOTS = {
  HOME: '/',
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  RESOURCE_HUB: '/resource-hub',
  LEARNING: '/learning',
  PLAYGROUND: '/playground',
};

// ----------------------------------------------------------------------

export const paths = {
  root: ROOTS.HOME,
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  product: {
    root: `/products`,
    checkout: `/products/checkout`,
    details: (id) => `/products/${id}`,
  },
  post: {
    root: `/blogs`,
    details: (slug) => `/blogs/${slug}`,
  },
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    verify: `${ROOTS.AUTH}/verify`,
  },

  // PLAYGROUND
  playground: {
    coding: `${ROOTS.PLAYGROUND}/coding`,
    blockly: `${ROOTS.PLAYGROUND}/blockly`,
  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      purchases: `${ROOTS.DASHBOARD}/purchases`,
    },
    stocks: {
      root: `${ROOTS.DASHBOARD}/stocks`,
    },
    resourcehub: {
      root: `${ROOTS.DASHBOARD}${ROOTS.RESOURCE_HUB}`,
      new: `${ROOTS.DASHBOARD}${ROOTS.RESOURCE_HUB}/new`,
      edit: (id) => `${ROOTS.DASHBOARD}${ROOTS.RESOURCE_HUB}/${id}/edit`,
    },
    devices: {
      root: `${ROOTS.DASHBOARD}/devices`,
      new: `${ROOTS.DASHBOARD}/devices/new`,
      details: (id) => `${ROOTS.DASHBOARD}/devices/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/devices/${id}/edit`,
    },
    registeredDevices: {
      root: `${ROOTS.DASHBOARD}/d`,
      register: `${ROOTS.DASHBOARD}/d/register`,
      details: (id) => `${ROOTS.DASHBOARD}/d/${id}`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/users`,
      new: `${ROOTS.DASHBOARD}/users/add`,
      list: `${ROOTS.DASHBOARD}/users/list`,
      details: (id) => `${ROOTS.DASHBOARD}/users/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/users/${id}/edit`,
    },
    product: {
      root: `${ROOTS.DASHBOARD}/products`,
      new: `${ROOTS.DASHBOARD}/products/new`,
      details: (id) => `${ROOTS.DASHBOARD}/products/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/products/${id}/edit`,
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
    },
    post: {
      root: `${ROOTS.DASHBOARD}/blogs`,
      new: `${ROOTS.DASHBOARD}/blogs/new`,
      details: (slug) => `${ROOTS.DASHBOARD}/blogs/${slug}`,
      edit: (slug) => `${ROOTS.DASHBOARD}/blogs/${slug}/edit`,
    },
    order: {
      root: `${ROOTS.DASHBOARD}/orders`,
      details: (id) => `${ROOTS.DASHBOARD}/orders/${id}`,
    },
    cart: {
      root: `${ROOTS.DASHBOARD}/cart`,
      details: (id) => `${ROOTS.DASHBOARD}/cart/${id}`,
    },
    forms: {
      root: `${ROOTS.DASHBOARD}/forms`,
      details: (id) => `${ROOTS.DASHBOARD}/forms/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/forms/${id}/edit`,
      new: `${ROOTS.DASHBOARD}/forms/new`,
    },
    questions: {
      root: `${ROOTS.DASHBOARD}/questions`,
      details: (id) => `${ROOTS.DASHBOARD}/questions/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/questions/${id}/edit`,
      new: `${ROOTS.DASHBOARD}/questions/new`,
    },
    examples: {
      root: `${ROOTS.DASHBOARD}/examples`,
      details: (id) => `${ROOTS.DASHBOARD}/examples/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/examples/${id}/edit`,
      new: `${ROOTS.DASHBOARD}/examples/new`,
    },
    account: {
      root: `${ROOTS.DASHBOARD}/account/profile`,
      profile: `${ROOTS.DASHBOARD}/account/profile`,
      settings: `${ROOTS.DASHBOARD}/account/settings`,
      preferences: `${ROOTS.DASHBOARD}/account/preferences`,
    },
  },
  resourcehub: {
    details: (id) => `${ROOTS.RESOURCE_HUB}/${id}`,
  },
  learning: {
    details: (type, id) => `${ROOTS.LEARNING}/${type}/${id}`,
  },
};
