import { CardHeader } from '@mui/material';
import React from 'react';

export default function CustomCardHeader({ title, avatar, subheader, action, ...rest }) {
  return (
    <CardHeader
      title={title}
      subheader={subheader}
      avatar={avatar}
      action={action}
      titleTypographyProps={{ variant: 'h3' }}
      subheaderTypographyProps={{ variant: 'body2' }}
      sx={{
        p: 0,
        m: 0,
        px: 1,
      }}
      {...rest}
    />
  );
}
