import { Divider, Stack } from '@mui/material';
import React from 'react';
import CustomTextField from 'src/components/custom-inputs/TextField';
import QuillEditor from 'src/components/quill/editor';

export default function TextContentEditor({ selectedContent, setSelectedContent }) {
  return (
    <div>
      <Divider>
        <p>Text Content Editor</p>
      </Divider>
      <Stack spacing={2}>
        <CustomTextField
          title="Title *"
          placeholder={'Enter title'}
          value={selectedContent?.title || ''}
          onChange={(e) => setSelectedContent({ ...selectedContent, title: e.target.value })}
        />
        <CustomTextField
          title="Subtitle"
          placeholder={'Enter subtitle'}
          value={selectedContent?.subtitle || ''}
          onChange={(e) => setSelectedContent({ ...selectedContent, subtitle: e.target.value })}
        />
        <QuillEditor
          placeholder={'Enter description/content here'}
          value={selectedContent?.description || ''}
          onChange={(value) => setSelectedContent({ ...selectedContent, description: value })}
        />
      </Stack>
    </div>
  );
}
